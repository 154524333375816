//import Select from "react-select"
import PropTypes from 'prop-types';
import "./App.css"

//import 'react-select/dist/react-select.css';

const React = require("react")
const Component = React.Component;
const Grapher = require('./grapher').default;
const helper = require("./helper");
const config = require("./config");

//export const formConfirm = FormConfirm;


class OutsideAlerter extends Component {
    constructor(props) {
      super(props);
// https://stackoverflow.com/questions/32553158/detect-click-outside-react-component  
      this.setWrapperRef = this.setWrapperRef.bind(this);
      this.handleClickOutside = this.handleClickOutside.bind(this);
    }

  /*
    componentDidMount() {
      document.addEventListener('mousedown', this.handleClickOutside);
    }
  
    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
  */
    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
      this.wrapperRef = node;
    }
  
    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
      if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
//        alert('You clicked outside of me!');
        console.log("click!");
      }
    }
  
    render() {
      return <div ref={this.setWrapperRef}>{this.props.children}</div>;
    }
  }
  
  OutsideAlerter.propTypes = {
    children: PropTypes.element.isRequired,
  };
  


class TextAreaPrompt extends Component {
 
render()
{
  if (!this.props.value)
  {
      if (document.getElementById(this.props.name))
          document.getElementById(this.props.name).value = "";
  }

    return(
<div className="form-group row">
<label htmlFor="description" className="col-sm-2 col-form-label">{this.props.title}:</label><div className="col-sm-6">
<textarea  className="form-control"  rows="4" onChange={this.props.onChange} cols="75" rows="10" 
value={this.props.value} id={this.props.eid} name={this.props.name} />
</div>
</div>
    )
}
}

export const textAreaPrompt = TextAreaPrompt

class TextPrompt extends Component {
 
  render()
  {
      if (!this.props.value)
      {
          if (document.getElementById(this.props.name))
              document.getElementById(this.props.name).value = "";
      }
      return(
  <>
    <label htmlFor="name" className="col-sm-2 col-form-label">{this.props.title}:</label><CompSpace/><CompSpace/><input className="col-sm-2 form-control" type='text'
       onChange={this.props.onChange} id={this.props.name} value={this.props.value} name={this.props.name} />
  </>
      )
  }
  }

export const textPrompt = TextPrompt  

class EmailPrompt extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            value: props.value };
//            name: props.name
      }
 
render()
{
    return(
<div class="form-group row">
  <label for="name" class="col-sm-2 col-form-label">{this.props.title}:</label><div class="col-sm-7"><input class="form-control" type='email' id={this.props.eid} onChange={this.props.onChange1} value={this.props.value} name='name' /></div>
</div>
    )
}
}


class FormContact extends Component {
    constructor(props) {
       super(props);
       this.state = { hasError: false };
   
     }
  
handleSend()
{

    // no need for config.back or api cuz helper does it
    helper.postToServer("/psupport", {email: document.getElementById("email").value, message: document.getElementById("message").value})
    .then(resp => {
        alert("Thanks for your message.  We will be in touch shortly.")
        document.getElementById("email").value = ""
        document.getElementById("message").value = ""

    })
    .catch(err => {
        alert(err)
    })

}

  render()
    {
      return(<div className="container">
      <br/>
      Please enter your message.
      <br/>
      <br/>
      <EmailPrompt title="Email" value={this.state.email} eid="email" />
      <TextAreaPrompt title="Message" value={this.state.message} eid="message"/>
      <input type="button" className="btn btn-primary" onClick={this.handleSend.bind(this)} value="Send"/>
      </div>)
    }
  
  
    } 
  
export const formContact = FormContact

class CompPager extends Component{
constructor(props){
    super(props)
    var nPages = props.pager.nRows/props.pager.pageSize
    var p = props.pager.offset-props.pager.pageSize
    if (p < 0)
        p = 0
    var n = props.pager.offset+props.pager.pageSize
    if (n > props.nRows-1)
        n = props.nRows-1
    this.state = {
        nPages: nPages,
        p: p,
        n: n,
    }

}
render() {
    var middle = []
    let errors;
    for (var i = 0; i < this.state.nPages; i++)
    {
        middle.push(<a className="w3-button" href={this.props.link+"o="+i*this.props.pager.pageSize}>{i+1}</a>)
    }
    if (!this.props.link)
    {
      errors = "Need link property"
    }
    else if (!this.props.pager)
    {
      errors = "Need pager object property"
    }
    return(
    <div className="w3-bar">
    {this.state.p}
    <a className="w3-button" href={this.props.link+"o="+this.state.p}>Previous</a>
    {middle}
    {errors}
    <a className="w3-button" href={this.props.link+"o="+this.state.n}>Next</a>
  </div>
    )

}
}

export const Pager = CompPager

class CompSearch extends Component {

    render()
    {
        return(
            <div>Topics:</div>
        )
    }
    }
    
    export const compSearch = CompSearch
    
    class CompSpace extends Component {
    /// OE??!!! Just a space
        render()
        {
            return(
                <>&nbsp;</>
            )
        }
        }
        
    export const Space = CompSpace
    
    
    class CompList extends Component {

        render(){

            return(
                <div className="w3-row" key="0">{this.props.children}</div>
            )

        }

    }
export const compList = CompList

    class CompListItem extends Component {

        render(){

            return(
                <div className="col-sm">{this.props.children}</div>
            )

        }

    }
export const compListItem = CompListItem

/*
const Modal = ({ handleClose, show, children }) => {
    const showHideClassname = show ? "modal display-block" : "modal display-none";
  
    return (
      <div className={showHideClassname}>
        <section className="modal-main">
        <div className="container">
  
          {children}
          <button onClick={handleClose}>Close</button>
          </div>
          <br/>
  
        </section>
      </div>
    );
  };
*/

class Modal extends React.Component {


render()
{
    return (
      <div className={this.props.show ? "modal display-block" : "modal display-none"}>
        <section className="modal-main" id={this.props.id}>
        <div className="container">
  
          {this.props.children}
          <button onClick={this.props.handleClose}>Close</button>
          </div>
          <br/>
  
        </section>
      </div>
    );
  }

}

  export const modal = Modal;


  class ModalForm extends React.Component {

    constructor(props)
    {
      super(props);
      this.state = {
        value: null,
        postItem:null,
      };
    }
/*    setData(data)
    {
        this.setState({value: data.content});

    } */
    componentDidMount()
    {
// it's mounted invisible.  We mainly care about when it's popped
    }
    handleInputChange(event) {

      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
    
      console.log(name)
      this.setState({
        [name]: value
      });
    
    }
    
    render()
    {
        return (
          
          <div className={this.props.show ? "modal display-block" : "modal display-none"}>
            <section className="modal-main" id={this.props.id}>
            <div className="container">
      Value: <input type="text" name="value" value={this.state.value} onChange={this.handleInputChange.bind(this)} /><br/>
              {this.props.children}
              <button onClick={() => {
                this.state.updateItem();
              }}>Save</button>
              <button onClick={() => {
                this.state.deleteItem();
              }}>Delete</button>

              <button onClick={() => {
                this.props.parent.setState({modalShowing:false});
              }}>Close</button>

              </div>
              <br/>
      
            </section>
          </div>
        );
      }
    
    }
    
export const modalForm = ModalForm;

  class MessageBox extends React.Component
  {
  
    handleClose()
    {
        this.props.parent.setState({showMessage: null});
    } 

  render()
  {
  
    const showHideClassname = this.props.parent.state.showMessage ? "modal display-block" : "modal display-none";
  

    return (
      <div className={showHideClassname}>
        <section className="modal-main">
        <div className="container">
        <br/>
          {this.props.parent.state.showMessage}<br/><br/>
          <button onClick={this.handleClose.bind(this)}>Close</button>
          </div>
          <br/>
  
        </section>
      </div>
    );
  }
}
export const messageBox = MessageBox;


function addCommas(nStr)
{
	nStr += '';
	var x = nStr.split('.');
	var x1 = x[0];
	var x2 = x.length > 1 ? '.' + x[1] : '';
	var rgx = /(\d+)(\d{3})/;
	while (rgx.test(x1)) {
		x1 = x1.replace(rgx, '$1' + ',' + '$2');
	}
	return x1 + x2;
}

class DataTable extends Component 
{
  constructor(props) 
  {
    super(props);
    var o = 0;
    var d = 0;
    if (props.order)
    {
      o = props.order;
      d = 1;
    }
    
    this.state = { 
      col: o,
      dir: d,
      keys: [],
          };
    this.columnClick = this.columnClick.bind(this);
  }

columnClick(which)
{

  var dir = this.state.dir;
  ++dir;
  if (dir > 1)
    dir = 0;
  this.setState({col: which.j, dir: dir})

}

componentDidMount() {


  var compares = JSON.parse(sessionStorage.getItem("compares"));
  if (!compares)
    compares = [];
  console.log(compares);

  this.setState({keys: compares})


}      

hic(event) {

  const target = event.target;
  const name = target.name;

  let value;
//    const value = target.type === 'checkbox' ? target.checked : target.value;
  if (target.type === 'checkbox')
  {
    value = this.state[name];
    if (!value)
      value = [];
      // assumes DOM won't feed us dupes
      var vint = target.value;

    if (target.checked)
    {
      value.push(vint);
    }
    else
    {
      var index = value.indexOf(vint);

      if (index !== -1) 
        value.splice(index, 1);

    }
  }
  else
  {
    value = target.value;
  }
  this.setState({
    [name]: value
  });

  console.log(this.state.keys);

}


render() {

    var graphLink = this.props.graphLink ? this.props.graphLink : "graph1";

    if (!this.state.keys)
    {
      return <div></div>
    }
    var cols = [];
    var i = 0;
    var col = this.state.col;

    cols.push(<td></td>);

    this.props.cols.map(item => {
      var j = i;
      var star = "";
      if (j == col)
        star = "*";
      cols.push(<td><a href="#" onClick={() => {
        this.columnClick({j})
      }
     
      }>{item}{star}</a></td>)
      i++;
    }
    )
    cols.push(<td><button onClick={() => {
      var s = "";
      this.state.keys.map(item => {
        if (s != "")
          s += ","
        s += item;
      })
      sessionStorage.setItem("compares", JSON.stringify(this.state.keys));
      document.location = `/${graphLink}?key=${s}`;

    }}>Compare</button><button onClick={() => {
        this.setState({keys: []});
    }}>Clear</button></td>)
    var rows = [];

    var sortCol = [];

    var i = 0;
    this.props.data.map(item => {
      sortCol.push([item[col], i++])
    })
    
    if (this.state.dir == 0)
      sortCol.sort(function(a, b){return a[0]-b[0]});
    else
      sortCol.sort(function(a, b){return b[0]-a[0]});

    var data = this.props.data;

//    var key = "US";
//    console.log(this.props.keys)

    var i = 1;
    sortCol.map(item => {
      var line = [];
//      console.log("keys");
//      console.log(this.props.keys);
      line.push(<td><a href={'/?pk='+this.props.keys[item[1]]}>{i}</a></td>)
      i++;
      var c = 0;
      data[item[1]].map(item1 => {
        if (c > 0)
        {
          item1 = addCommas(item1);
          line.push(<td align="center">{item1}</td>)
        }
        else
        {
          line.push(<td><a href={`/${graphLink}?key=`+this.props.keys[item[1]]}>{item1}</a></td>)
        }
        c++;
        
      })

      line.push(<td><input type="checkbox" name="keys" checked={this.state.keys.indexOf(this.props.keys[item[1]]) != -1} value={this.props.keys[item[1]]} onClick={this.hic.bind(this)}/></td>)
      rows.push(<tr key={this.props.keys[item[1]]}>{line}</tr>)
    })

        return(<div>
          <table className="DataTable">
          <thead>
              {cols}
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>

        </div>)
    
    
      } 
  }

export const dataTable = DataTable


class select1 extends React.Component
{

  componentDidMount()
  {
    
  }
  render()
  {
    var list = [];
//    list.push(<option>Please Select</option>)
if (this.props.options)
{
    this.props.options.map(item => {
      list.push(<option key ={item.id} value={item.id}>{item.name}</option>);
    })
  }
   
    return (<>
    <select name={this.props.name} value={this.props.value} onChange={this.props.hic}>
      {list}
    </select>
    </>);
  }


}

export const Select1 = select1


export function addField(page, form, item)
{
  
  if (item.type == "textarea")
  {
    form.push(<TextAreaPrompt title={item.title} name={item.name} value={page.state[item.name]} onChange={page.hic.bind(page)} />)
  }
  else if (item.type == "graph")
  {
    form.push(<Grapher datasets={[[1,2,3,4,5]]} keys={['1']} labels={['1','2','3','4','5']} />);
  }

}


class uploader extends React.Component
{
/*
Properties:
objectId
uploadLink
name (actually id)
dw
???
mediaTag
ext

REMEMBER Needs Ref in order to activate properly


Backend:
1. Remember to handle with 
router.post('/link', uploader.single('file'), rest_x.postXXX)
2. dw=saveFile, saveFile1, etc.  Look in that function

*/
  constructor(props) 
  {
    super(props);
    this.state = {
      id: null,
      image: null
    }
    this.extTypes = ["png", "jpg", "xlsx", "txt"];
}
// Note - this needs an element ID to work properly. 
// it uses the name as the ID.  The 

activate()
{
  this.setState({image: `${this.props.displayLink}/${this.props.mediaTag}-${this.props.objectId}_tn.png`})
}

  onFile() {
  
    var file = document.getElementById(this.props.name).files[0];
    console.log(file.name);
    var ext = file.name.substring(file.name.lastIndexOf(".")+1).toLowerCase();
    var types = this.props.extTypes;
    if (!types)
      types = this.extTypes;

    if (types.indexOf(ext) == -1)
    {
      alert("Can't handle " + ext);
      return;
    }
    var formData = new FormData();

    formData.append('file', file);
    formData.append('id', this.props.objectId)
    formData.append("dw", this.props.dw)
    
    fetch(config.back+this.props.uploadLink, {
      method: 'POST', credentials: "same-origin",
      body: formData
    }).then(response => response.json())
    .then(res => {
     this.setState({image: `${this.props.displayLink}/${this.props.mediaTag}-${this.props.objectId}_tn.png?`+new Date().getMilliseconds()}, 
          () => { 
            alert("file was uploaded")});
    })
    .catch(error => { 
      alert(error);
    });
   //.then(response => console.log('Success:', response));
    
  }
    

  /*
  
  componentDidMount()
  {
    This only gets called once during parent form creation, not when modal form is shown
    console.log(this.state.image);
  }
  */
  render()
  {
  
    return (<>
     <input type="file" class="form-control-file" id={this.props.name}  onChange={this.onFile.bind(this)} />
      <img width="40" src={this.state.image} />
      {this.state.image}
     <div class="progress">
          <div class="progress-bar" role="progressbar" style={{width: this.state.progress}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
     </div>


    </>);
  }


}

export const Uploader = uploader


const currentDate = new Date();

const categories = [
  { name: "Last 5 Years", dateBegin: findDate(1, 5*12), dateEnd: findDate() },
  { name: "All", dateBegin: null, dateEnd: null},
  { name: "Custom", dateBegin: null, dateEnd: null },
  { name: "Today", dateBegin: findDate(), dateEnd: findDate() },
  { name: "Yesterday", dateBegin: findDate(1), dateEnd: findDate(1) },
  { name: "This Week", dateBegin: findDate(currentDate.getDay()), dateEnd: findDate() },
  { name: "Last Week", dateBegin: findDate(7 + currentDate.getDay()), dateEnd: findDate(7 - currentDate.getDay()) },
  { name: "Past 7 Days", dateBegin: findDate(7), dateEnd: findDate() },
  { name: "This Month", dateBegin: findDate(currentDate.getDate() - 1), dateEnd: findDate() },
  { name: "Last Month", dateBegin: findDate(1, 1), dateEnd: findDate(currentDate.getDate()) },
  { name: "Past 30 Days", dateBegin: findDate(30), dateEnd: findDate() },
  { name: "Past 6 Months", dateBegin: findDate(1, 6), dateEnd: findDate() },
  { name: "This Year", dateBegin: findDate(1, currentDate.getMonth()), dateEnd: findDate() },
  { name: "Past 12 Months", dateBegin: findDate(1, 12), dateEnd: findDate() },
];

function findDate(dayBuffer, monthBuffer) {
    let curr = new Date();

    if (dayBuffer) {
      if (monthBuffer) {
        curr.setDate(dayBuffer);
        curr.setMonth(curr.getMonth() - monthBuffer);
      } else {
        curr.setDate(curr.getDate() - dayBuffer);
      }
    }

    return curr.toISOString().substr(0,10);
}


class datePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: this.props.cat? this.props.cat : 0,
      dateBegin: this.props.startDate? this.props.startDate : categories[0].dateBegin,
      dateEnd: this.props.endDate? this.props.endDate: categories[0].dateEnd,
    };
    this.props.parent.setState({date1:this.state.dateBegin, date2: this.state.dateEnd})
    if (this.props.setDateFilter)
    {
      this.setDateFilter = this.props.setDateFilter.bind(this);
      this.setDateFilter(this.state.dateBegin, this.state.dateEnd, this.state.category);
    }
  }

  componentDidMount() {
    if (this.setDateFilter)
    {
      this.setDateFilter(this.state.dateBegin, this.state.dateEnd, this.state.category);

    }
  }

  handleSelect(event) {
    const value = event.target.value;

    console.log(value)
    // Future proofing for potental cases
    if (!categories[value].dateBegin) {
      document.getElementById("dateBegin").value = "";
    }
    if (!categories[value].dateEnd) {
      document.getElementById("dateEnd").value = "";
    }

    // Callback to set the dates in the parent once the initial setState function is finished
    this.props.parent.setState({date1: categories[value].dateBegin, date2: categories[value].dateEnd });
    this.setState({ category: value, dateBegin: categories[value].dateBegin, dateEnd: categories[value].dateEnd }, () => {
      if (this.setDateFilter)
        this.setDateFilter(this.state.dateBegin, this.state.dateEnd, this.state.category);
    });
  }

  // Man, callbacks really suck, don't they?
  // (Closure just so only silly callback will work correctly)
  checkDate(name, oldDate) {
    return () => {
      if (Date.parse(this.state.dateBegin) > Date.parse(this.state.dateEnd)) {
        this.setState({[name]: oldDate});
        alert("Invalid Date Range");
      } else {
        this.setState({category: 2})
        this.props.parent.setState({date1: this.state.dateBegin, date2: this.state.dateEnd });

        if (this.setDateFilter)
          this.setDateFilter(this.state.dateBegin, this.state.dateEnd);
      }
    }
  }

  handleChange(event) {
    const oldDate = this.state[event.target.name];

    this.setState({[event.target.name]: event.target.value}, this.checkDate(event.target.name, oldDate));
    
  }

  handleSubmit(event) {
    event.preventDefault();
    
    this.setDateFilter(this.state.dateBegin, this.state.dateEnd, this.state.category);
  }

  render() {
    return (
      <div>
        Date Range:
        <form onSubmit={this.handleSubmit.bind(this)}>
          <label>
            Category:{" "}
            <select
              value={this.state.category}
              onChange={this.handleSelect.bind(this)}
            >
              {categories.map((item, index) => (
                <option key={index} value={index}>
                  {item.name}
                </option>
              ))}
            </select>
          </label>
          <label>
            {" "}Start Date:{" "}
            <input
              id="dateBegin"
              type="date"
              name="dateBegin"
              onChange={this.handleChange.bind(this)}
              value={this.state.dateBegin}
            />
          </label>
          <label>
            {" "}End Date:{" "}
            <input
              id="dateEnd"
              type="date"
              name="dateEnd"
              onChange={this.handleChange.bind(this)}
              value={this.state.dateEnd}
            />
          </label>
          {/*
            {" "}
            <input type="submit" value="Set" />
          */}
        </form>
      </div>
    );
  }
}

export const DatePicker = datePicker
