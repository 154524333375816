import React from "react";
const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const TextPrompt = require("../comp_misc").textPrompt;
const TextAreaPrompt = require("../comp_misc").textAreaPrompt;
const misc = require("../comp_misc");

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/user/form_predictors";


class ModalList1 extends React.Component {
  
  constructor(props)
  {
    super(props);
    var q = helper.parseQuery();
    this.state = {
      id: q.id,
            
    };
  }


 componentDidMount() {
  }


  
  hic(event) {

    const target = event.target;
    const name = target.name;

    let value;
//    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.type === 'checkbox')
    {
      value = this.state[name];
      if (!value)
        value = [];
        // assumes DOM won't feed us dupes
        var vint = parseInt(target.value);

      if (target.checked)
      {
        value.push(vint);
      }
      else
      {
        var index = value.indexOf(vint);
        if (index !== -1) value.splice(index, 1);
      }
    }
    else
    {
      value = target.value;
    }
    this.setState({
      [name]: value
    });
  
  }

    

  render() {
document.title = "Predictors";


return(

<div className="container">
  <br/>
1. Graph daily profit vs S&P, individual stock, prediction<br/><br/>
2. Graph total profit vs S&P, individual stock, prediction<br/><br/>
2. Model Info -- type<br/><br/>
3. Data info

</div>
)

}
}

export default ModalList1


