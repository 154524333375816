import React from 'react';
//import ReactDOM from 'react-dom';
//import createHistory from 'history/createBrowserHistory'

//import { config } from './config.js';
//import FacebookLogin from 'react-facebook-login';
import "./App.css"
// https://www.npmjs.com/package/react-facebook-login
import { GoogleLogin } from 'react-google-login';
//import Cookies from 'universal-cookie';
const config = require('./config.js');
const helper = require("./helper");

//const history = createHistory()

// https://www.npmjs.com/package/react-google-login

//const toReactElement = require('jsonml-to-react-element');

// FB Test user	radpgivgic_1530027171@tfbnw.net

//const cookies = new Cookies();

/*
var fbStyle = {
  backgroundColor:0xff0000,
  fontSize:"2px",
  width:"300px",
  height:"160px"
}
*/

/*
.kep-login-facebook{font-family:Helvetica,
  sans-serif;
  font-weight:700;-webkit-font-smoothing:antialiased;color:#fff;
  cursor:pointer;display:inline-block;
  font-size:calc(.27548vw + 12.71074px);
  text-decoration:none;text-transform:uppercase;
  transition:background-color .3s,border-color .3s;
  background-color:#4c69ba;
  border:calc(.06887vw + .67769px) solid #4c69ba;
  padding:calc(.34435vw + 13.38843px) calc(.34435vw + 18.38843px)}
  
  .kep-login-facebook.small{
    padding:calc(.34435vw + 3.38843px) calc(.34435vw + 8.38843px)}
  .kep-login-facebook.medium{
    padding:calc(.34435vw + 8.38843px) calc(.34435vw + 13.38843px)}
    .kep-login-facebook.metro{border-radius:0}
    .kep-login-facebook .fa{margin-right:calc(.34435vw + 3.38843px)}
*/

/*
const responseFacebook = (response) => {
  
fetch(config.back+'/cuser1', {
  method: 'POST', credentials: "same-origin",
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(response),
}).then(res => res.json())
.catch(error => console.error('Error:', error))
.then((response) => 
{
  finishLogin(response)

})


}

const responseGoogle = (response) => 
{
  fetch(config.back+'/cuser2', {
    method: 'POST', credentials: "same-origin",
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(response),
  }).then(res => res.json())
  .catch(error => console.error('Error:', error))
  .then((response) => 
  {
    finishLogin(response)
  
  })
  
  }
*/

class  Login extends React.Component {

  constructor()
  {
    super();


    this.state = {
      email: "",
      password: "",
	error: ""
    };

    this.handleSubmit = this.handleSubmit.bind(this);


  }

  async componentDidMount() {

    var r = await helper.getFromServer("/uid");

    r = r.result;

    if (r.name) {
      this.finishLogin({result: "OK", name: r.name, roles: r.roles})
      return;
    }

    this.setState({tested:true});

  }

handleSubmit(event) {
    event.preventDefault();


fetch(config.back+'/login', {
  method: 'POST', credentials: "same-origin",
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    user: event.target.elements.email.value,
    password: event.target.elements.password.value,
  }),
}).then(res => res.json())
.catch(error => console.error('Error:', error))
.then((response) => 
{
  this.finishLogin(response)


});


}
finishLogin(response)
{
// OE!!! This is where to put the TOS agreement!!!!
// check response.tos

  console.log("response", response)

	if (response.result === "OK")
	{

//    sessionStorage.setItem("connection", cookies.get("connect.sid"))
		sessionStorage.setItem("name", response.name)
//    sessionStorage.setItem("uid", response.uid)
    if (response.roles)
    {
      if (response.roles.indexOf("admin") !== -1)
        sessionStorage.setItem("admin", true)
    }

//		this.setState({error: "Worked!"});
    if (sessionStorage.getItem("rd"))
//      history.replace(sessionStorage.getItem("rd"))
      document.location = sessionStorage.getItem("rd");
    else
      document.location = "/home"
//      history.replace('/home')

    sessionStorage.removeItem("rd")

	}
	else
	{
		sessionStorage.setItem("name", null)
		this.setState({error: "Sorry!"});
	}


}

 
  render() {

    if (!this.state.tested)
    {
      return(<></>)
    }


 return(
<div className="container">
<br/>
Please Note: This site is under development, and ALL DATA IS SUBJECT TO DELETION! By logging in, you agree to these terms.
<br/>
<br/>
<form  onSubmit={this.handleSubmit}>
<div className="col-md-6">
	<input type="text" name="email" className="form-control" id="email" placeholder="you@example.com" autoFocus/><br/>
	<input type="password" name="password" className="form-control" id="password" placeholder="Password"/><br/>

{this.state.error}
  <button type="submit" className="btn btn-primary">Login</button>
</div>

</form>
<br/>

{/*
<FacebookLogin
    appId="1928506304126215"
    autoLoad={false}
    fields="name,email,picture"
    cssClass="fb1"
    callback={responseFacebook} />
    <br/>
    <br/>
<GoogleLogin
 clientId="444319830323-1mo16oaljo03kjenuhbnlr903qh4p0t3.apps.googleusercontent.com"
 buttonText="Login with Google"
 onSuccess={responseGoogle}
 onFailure={responseGoogle}
/> 
*/}   
</div>
 
    );
}
 }

export default Login;

