import React, { useState, useEffect, useRef } from "react";
import * as THREE from 'three';
const om3d = require("../3d/om3d");
const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const TextPrompt = require("../comp_misc").textPrompt;
const TextAreaPrompt = require("../comp_misc").textAreaPrompt;
const misc = require("../comp_misc");


// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/user/static_configs";

function looseJsonParse(obj) {
  return Function('"use strict";return (' + obj + ')')();
}

const scene = new THREE.Scene();
// https://stackoverflow.com/questions/64374823/how-to-declare-a-variable-inside-a-functionnal-component-in-reactjs

function ModalList1() {
  
    const q = helper.parseQuery();
  const id = q.id;

  let cube;

  const mountRef = useRef(null);

  console.log("list!")
  
    const [form, setForm] = useState({
      name: "",
      description: "",
      config: "",
    });


    useEffect(() => {    
      console.log("effect!");
      fillList();


    
    var camera = new THREE.PerspectiveCamera( 75, window.innerWidth/window.innerHeight, 0.1, 1000 );
    var renderer = new THREE.WebGLRenderer();
    
    renderer.setSize( window.innerWidth, window.innerHeight );
    
    mountRef.current.appendChild( renderer.domElement );
    
    var geometry = new THREE.BoxGeometry( 1, 1, 1 );
    var material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
    cube = new THREE.Mesh( geometry, material );
    
    scene.add( cube );
    console.log(scene)
    camera.position.z = 5;
    
    var animate = function () {
      requestAnimationFrame( animate );
      cube.rotation.x += 0.01;
      cube.rotation.y += 0.01;
      renderer.render( scene, camera );
    };
    
    animate();

    return () => mountRef.current.removeChild( renderer.domElement);
    }, []);    

const fillList = () => {
  console.log("filling!", id)
  helper.getFromServer(g_link+`?dw=get&id=${id}`)
  .then(res => {
//    console.log(res);
//    form.name = res.result.name;
//    form.description = res.result.description;

    setForm(previousState => {
      return { ...previousState, name: res.result.name, description: res.result.description, type: res.result.type, config: res.result.config }});

//    this.setState({showForm: false, rows: res.result, options: res.options});
  })}

/*
 componentDidMount() {
  this.fillList()  
  }
*/

const hic = (event) => {

  const target = event.target;
  const name = target.name;

  let value;
//    const value = target.type === 'checkbox' ? target.checked : target.value;
  if (target.type === 'checkbox')
  {
    value = this.state[name];
    if (!value)
      value = [];
      // assumes DOM won't feed us dupes
      var vint = parseInt(target.value);

    if (target.checked)
    {
      value.push(vint);
    }
    else
    {
      var index = value.indexOf(vint);
      if (index !== -1) value.splice(index, 1);
    }
  }
  else
  {
    value = target.value;
  }
  setForm(previousState => {
    return { ...previousState, [name]: value }});

  
}

  const saveItem = () => {
  
    helper.postToServer(g_link, {dw: "update", id: id, name: form.name,description: form.description,type: form.type, config: form.config}, true)
.then(res => {
    console.log("posted!")
//      this.fillList();
    })
    .catch(err => {
      this.setState({error: err});
    })

  }

  const refresh = () => {

    try
    {
      var config = looseJsonParse(form.config);

      if (scene.children[0])
        scene.remove(scene.children[0]);

        var shape = om3d.get3Geometry(config);
        scene.add(shape);
        
    }
    catch (e)
    {
      console.log(e);
      alert(e);
    }

//    console.log(scene);



  }

  
return(

<div style={{display: "flex", flexDirection: "row"}}>
  <div>
  <br/>
          <div><TextPrompt name="name" title="Name" onChange={hic} value={form.name}/></div>
<div><TextPrompt name="description" title="description" onChange={hic} value={form.description}/></div>
<div><TextPrompt name="type" title="type" onChange={hic} value={form.type}/></div>
<div><TextAreaPrompt name="config" title="Config" onChange={hic} value={form.config}/></div>

<button type="button" className="btn btn-primary" onClick={refresh}>Refresh</button>&nbsp;
        <button type="button" className="btn btn-primary" onClick={saveItem}>Save</button>&nbsp;
        </div>
        <div style={{margin: "20px"}}  ref={mountRef}>

        </div>
</div>
)

}


export default ModalList1
