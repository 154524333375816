import React, { Component } from 'react';
const config = require('./config.js')

class Header extends Component {

showMenu()
{
  let admin = "";
  let login = <a href="/login" className="w3-bar-item w3-button">Login</a>;
  let user = "";
  //let finance =  <a href="/finance" className="w3-bar-item w3-button">Finance</a>;
  let finance;
  
  
//  <a href="/user/ctypes" className="w3-bar-item w3-button">CTypes</a>

  if (sessionStorage.getItem("name"))
  {
    finance = "";
    login = <a href="/logout" className="w3-bar-item w3-button">Logout</a>;
    
    user = <>
<div className="w3-dropdown-hover">
      <button className="w3-button">Pojects</button>
      <div className="w3-dropdown-content w3-bar-block w3-border">
      <div><a href="/user/projects" className="w3-bar-item w3-button">Project List</a></div>
      <div><a href="/user/todo" className="w3-bar-item w3-button">Todo list</a></div>
      <div><a href="/user/timesheets" className="w3-bar-item w3-button">Timesheets</a></div>
    </div>
    </div>

    <div className="w3-dropdown-hover">
    <button className="w3-button">Modeling</button>
    <div className="w3-dropdown-content w3-bar-block w3-border">
    <a href="/user/static_configs" className="w3-bar-item w3-button">Static Configs</a>
    <a href="/user/model_trains" className="w3-bar-item w3-button">Model Trains</a>
    <a href="/user/feed" className="w3-bar-item w3-button">Model Feed</a>
    <a href="/user/cmodeler" className="w3-bar-item w3-button">Modeler</a>
    <a href="/user/tests" className="w3-bar-item w3-button">Automated Tests</a>
    <a href="/user/segmentations" className="w3-bar-item w3-button">Segmentations</a>
    <a href="/user/generators" className="w3-bar-item w3-button">Generators</a>
    <a href="/user/classifiers" className="w3-bar-item w3-button">Classifiers</a>
    <a href="/user/trans_sets" className="w3-bar-item w3-button">Transition Sets</a>
    <a href="/user/transitions" className="w3-bar-item w3-button">Transitions</a>
    <a href="/user/loops" className="w3-bar-item w3-button">Learning loops</a>
    <a href="/user/list_csc" className="w3-bar-item w3-button">Combination Space Counters</a>
</div>
    </div>
    <div className="w3-dropdown-hover">
    <button className="w3-button">Data</button>
  <div className="w3-dropdown-content w3-bar-block w3-border">
  <a href="/user/dsets" className="w3-bar-item w3-button">Data Sets</a>
    <a href="/user/lsets" className="w3-bar-item w3-button">Layer Sets</a>
    <a href="/user/vis" className="w3-bar-item w3-button">Visualize</a>
    <a href="/user/media" className="w3-bar-item w3-button">Media</a>
    <a href="/user/dnns" className="w3-bar-item w3-button">Dnns</a>
    </div>
    </div>

    <div className="w3-dropdown-hover">
    <button className="w3-button">Finance</button>
      <div className="w3-dropdown-content w3-bar-block w3-border">
      <div>
      <a href="/admin/finance/strategies" className="w3-bar-item w3-button">Strategies/Algorithms</a>
      <a href="/user/finance/portfolios" className="w3-bar-item w3-button">My Portfolios</a>
      </div>
    </div>
    </div>

    </>;


  }

 if (sessionStorage.getItem("admin"))
{
  admin =  <div className="w3-dropdown-hover">
  <button className="w3-button">Admin</button>
  <div className="w3-dropdown-content w3-bar-block w3-border">
<a href="/admin/users"  className="w3-bar-item w3-button">Users</a>
<a href="/admin/tokens"  className="w3-bar-item w3-button">Tokens</a>
<a href="/admin/restart"  className="w3-bar-item w3-button">Careful!</a>
<a href="/api/admin/logs"  className="w3-bar-item w3-button">Logs</a>
<a href="/admin/bots"  className="w3-bar-item w3-button">Bots</a>
<a href="/admin/plugins" className="w3-bar-item w3-button">Plugins</a>
<a href="/admin/loops" className="w3-bar-item w3-button">Loops</a>

  </div>
</div>              
}  
    return (

      <div className="w3-bar w3-green">
      <a href="/home" className="w3-bar-item w3-button">Home</a>
      {finance}
      <a href="/about" className="w3-bar-item w3-button">About</a>
      <a href="/contact" className="w3-bar-item w3-button">Contact</a>
      {user}
      {admin}
      {login}
      </div> 
    )
    

}

componentDidMount()
{
//  document.title = config.name;
  //console.log(config.back);
//  fetch(config.back+'/alog?u='+encodeURIComponent(window.location.href));
}
  render() {
/*    
    var secure = "";
    if (config.back === "http://localhost:3000/api") {
      secure = <a href="http://127.0.0.1:3001/">Secure</a>;
    }
*/
    return (
      <div>
      <div className="w3-container w3-teal">
     <div style={{ maxWidth: '800px'}}>
      <h1>{config.name}</h1>
      <h5>{sessionStorage.getItem("name")}</h5>
    </div>
    </div>

    <div  style={{display: 'flex', justifyContent: 'center'}}>{this.showMenu()}</div>
    </div>
    );


  }
}



export default Header;


