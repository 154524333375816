const React = require("react")
//const tokenize = require("./helper.js").tokenize;
const helper = require("./helper");
const config = require("./config");

export const clipTypes = [
{name:"direction", title: "Direction"},

];


/*
function parseElement(line)
{
  var e = new Object();
  var a = tokenize(line);
  for (var i = 1; i < a.length; i += 3)
  {
    e[a[i]] = a[i+2];
  }
  return e;
}
*/

class StForm
{
    constructor()
    {
        this.fields = [];
        this.names = [];
        this.parent = null;
        this.type = "";
        this.saveType = 0;
    }

    addField(name, type, values)
    {
        this.names.push(name);
        this.fields.push(<p>{name}: </p>);
//        this.fields.push(": ");

      if (type == "textarea")
      {
        this.fields.push(<p><textarea id={name} rows="3" cols="40" /></p>);

      }
      else if (type == "select" && values != null)
      {
        this.fields.push(<p><input id={name} /></p>);
        
      }
      else if (type == "image")
      {
        this.fields.push(<p><img id={`${name}-image`} src={`/api/tn/media${this.values[name]}_tn.png`}/> 
        <input type="file" class="form-control-file" id={`${name}-file`} onChange={(e) => {

/*          console.log(e.target.value);
          console.log(document.getElementById(`${name}-image`).value)

          console.log(file.name);
          var ext = file.name.substring(file.name.lastIndexOf(".")+1).toLowerCase();
          var types = this.props.extTypes;
          if (!types)
            types = this.extTypes;
      
          if (types.indexOf(ext) == -1)
          {
            alert("Can't handle " + ext);
            return;
          } */
          var formData = new FormData();

          var file = document.getElementById(`${name}-file`).files[0];      
          formData.append('file', file);
          formData.append('id', this.values[name]);
          formData.append("dw", "saveFile");
          
          fetch(config.back+this.link+"_up", {
            method: 'POST', credentials: "same-origin",
            body: formData
          }).then(response => response.json()) 
          .then(res => {
/*           this.setState({image: `${this.props.displayLink}/${this.props.mediaTag}-${this.props.objectId}_tn.png?`+new Date().getMilliseconds()}, 
                () => { 
                  alert("file was uploaded")}); */
//                  alert(this.values[name])
//                  console.log(`/api/tn/media${this.values[name]}_tn.png?` + new Date().getMilliseconds())
                  document.getElementById(`${name}-image`).src = `/api/tn/media${this.values[name]}_tn.png?` + new Date().getMilliseconds();
          }
          ) 
          .catch(error => { 
            alert(error);
          });
                  
          
        }} />
        </p>);
        
      }
      else
      {
        this.fields.push(<p><input id={name} /></p>);
      }

    }

    initChart()
    {
       
      this.type = "chart";

      this.fields = [];
      this.names = [];
      this.addField("duration", "float");
//      this.addField("order", "int");
    }

    initPic()
    {
      this.type = "pic";
      this.names = [];
      this.addField("leftId", "image");
      this.addField("rightId", "image");

    }


    init(type, values)
    {
      this.values = values;
      if (type == "chart")
        this.initChart();
      else if (type == "pic")
        this.initPic();
    }

    setData(json)
    {
//        var a = parseElement(line);
      var a = json;
      if (!json)
        return;
//console.log(a);
        var k = Object.keys(a);
        for (var i = 0; i < k.length; i++)
      {
        if (document.getElementById(k[i]) == null)
          continue;
//        console.log(k[i]);
        document.getElementById(k[i]).value = a[k[i]];
      }


    }

    save1()
    {
      var lines = document.getElementById("lines").value;
      if (!lines || lines.trim() == "")
      {
        helper.showError("Please enters some lines");
        return;
      }
      helper.postToServer("/user/scenes", {id: this.id, dw: "parseLines", lines: lines})
      .then(res =>{
        if (res.code !== 200)
          alert(res.result)
        this.parent.hideModal();
        this.parent.fetchRows();
      })
      .catch(err => {
        alert(err);
      })
    }
    save()
    {
/*      if (this.saveType == 1)
      {
        this.save1();
        return;
      }
      var line = this.type + ": "; */

//        let order;
        var jobj = {type: this.type};

        for (var i = 0; i < this.names.length; i++)
        {
/*          
          if (this.names[i] == "order")
          {
            order = document.getElementById(this.names[i]).value;
            continue;
          } */
//            line += this.names[i] + '="' + this.parent.state[this.names[i]] + '" ';
//            line += this.names[i] + '="' + document.getElementById(this.names[i]).value + '" ';
            jobj[this.names[i]] = document.getElementById(this.names[i]).value;
}


    console.log("saving1", this.link, this.id)
      helper.postToServer(this.link, {dw:'updateJobj', id: this.id, jobj: jobj})
      .then(res =>{
        if (res.code !== 200)
          alert(res.result)
        this.parent.hideModal();
        this.parent.fetchRows();
      })
      .catch(err => {
        alert(err);
      })
    }
    show()
    {
      this.parent.setState({ showGen: true, form: this, fields: this.fields});
    }

}

export default StForm