import {PtForm, getSelectType} from "./PtForm";
import "./tv.css";

const React = require("react")
const config = require('./config.js');
const helper = require('./helper');
const TextPrompt = require("./comp_misc").textPrompt
const TextAreaPrompt = require("./comp_misc").textAreaPrompt;
//const moment = require("moment");

const ModalForm = require("./comp_misc").modalForm;

var g_link = "/user/pelements"
let g_pid;
let g_main;
let g_parent;

let fetchTree; // blog on this!!!!

var treeRecord = {
  fields: ["value"]
};

class TreeItemComponent extends React.Component
{

  constructor(props)
  {
    super(props);

    this.state = {
      class: "caret",
      active: "nested"
    }
  }

  editElement()
  {
//    console.log(this.props.content);
    document.getElementById("description").value = this.props.content.description;
    
    g_parent._modalForm.setState({value: this.props.content.name, 
      updateItem:this.updateItem.bind(this),deleteItem:this.deleteItem.bind(this)});
    g_parent.setState({modalShowing:true});
  }

  updateItem()
  {

    var description = document.getElementById("description").value;

    helper.postToServer(g_link, {dw: 'update', _id: this.props.id, pid: g_pid, 
      name: g_parent._modalForm.state.value, description: description})
    .then(res => {
        if (res.code !== 200)
            alert("Handle Errors Please!");
        fetchTree();
    })
    .catch(err =>{
        alert("Server says: "+err)
    })


    g_parent.setState({modalShowing:false});

  }

  deleteItem()
  {

    helper.postToServer(g_link, {dw: 'delete', _id: this.props.id})
    .then(res => {
        if (res.code !== 200)
            alert("Handle Errors Please!");
        fetchTree();
    })
    .catch(err =>{
        alert("Server says: "+err)
    })


    g_parent.setState({modalShowing:false});

  }

  addElement()
  {
   
    helper.postToServer(g_link, {dw: 'add', pid: g_pid, main:g_main, parent:this.props.id})
    .then(res => {
        if (res.code !== 200)
            alert("Handle Errors Please!");
        fetchTree();
    })
    .catch(err =>{
        alert("Server says: "+err)
    })

  }
  calcElement()
  {
    helper.getFromServer(g_link+"?dw=calcElement&id="+this.props.id+"&pid="+g_pid+"&main="+g_main)
    .then(res => {
      alert(res.result);
    })
    .catch(err =>
    {
      alert(err);
    });

/*   
    helper.postToServer(g_link, {dw: 'add', pid: g_pid, main:g_main, parent:this.props.id})
    .then(res => {
        if (res.code !== 200)
            alert("Handle Errors Please!");
        fetchTree();
    })
    .catch(err =>{
        alert("Server says: "+err)
    })
*/
  }

  expand()
  {
    if (this.state.active == "nested")
    {
      this.setState({class: "caret1"});
      this.setState({active: "active"});
    }
    else
    {
      this.setState({class: "caret"});
      this.setState({active: "nested"});
    }
  }
  render()
  {
    var dd = this.props.content.name;
    if (this.props.numChildren != 0)
      dd = <span className={this.state.class} onClick={this.expand.bind(this)}>{this.props.content.name}</span>;
/*    if (this.props.numChildren == 1000)
    {
    return (
      <li>{this.props.content.name}&nbsp;
<div className="dropdown">
  <button className="dropbtn">...</button>
  <div className="dropdown-content">
    <a href="#" onClick={this.addElement.bind(this)}>Add</a>
    <a href="#" onClick={this.editElement.bind(this)}>Update</a>
  </div>
  x
</div> 
      </li>
    )
    }
      else
      { */
    return (
      <li>{dd}
      &nbsp;<div className="dropdown">
  <button className="dropbtn">...</button>
  <div className="dropdown-content">
    <a href="#" onClick={this.addElement.bind(this)}>Add</a>
    <a href="#" onClick={this.editElement.bind(this)}>Update</a>
    <a href="#" onClick={this.calcElement.bind(this)}>Calculate</a>
  </div>
  {this.props.content.description}
</div> 

        <ul className={this.state.active}>{this.props.renderChildren()}</ul>
      </li>
    )
      }

//  }
}

class TreeItem 
{
  constructor(type, content, id)
  {
//    console.log(content);

    this.type = type;
    this.content = content;
//    this.description = content.descriptiondescription;
    this.id = id;
    this.children = [];
  }
  addChild(item)
  {
    this.children.push(item);
  }

  addElement()
  {
    alert("add!");
  }
  expand()
  {
//    alert("expand!");
//    console.log(this);

  }
  renderChildren()
  {
    if (this.children.length == 0)
      return;

    var a = [];
    for (var i = 0; i < this.children.length; i++)
    {
      var item = this.children[i];
//      console.log(item);
      if (item)
        a.push(item.render());
    }

    return a;
  }
  render()
  {

    if (this.children.length == 100)
    {
     return (
      <li>{this.content}</li>
    )
  }
  else
  {
 return (
  <TreeItemComponent 
  content={this.content} 
  data={this} numChildren={this.children.length} id={this.id} 
  renderChildren={this.renderChildren.bind(this)} />
 )

  }
  }
  
}

class ListTelements extends React.Component
{
  constructor()
  {
    super();
    var q = helper.parseQuery(document.location.search);
    this.pid = q.pid;
    this.main = q.id;
    g_pid = this.pid;
    g_main = this.main;
    this.state = {
      nodes:[],
    }
    g_parent = this;
    fetchTree = this.fetchTree.bind(this);
  }

  fetchTree()
  {

    var nodes = [];
    helper.getFromServer("/user/pelements?dw=getMain&main="+this.main+"&pid="+this.pid)
    .then(res => {
      var main = res.result.main;
      var docs = res.result.docs;

      var node = {id:main._id,value:main,parent:null};
      nodes[node.id] = node; 

      for (var i = 0; i < docs.length; i++)
      {
        node = {id:docs[i]._id, value:docs[i], parent:docs[i].parent};
        nodes[node.id] = node;
      }

      this.setState({nodes:nodes});
    })
    .catch(err => 
    {
      console.log(err);
      alert("An error has occurred");
    })

  }
  componentDidMount()
  {
    fetchTree();
  }

render()
{

var nodes = this.state.nodes;
var keys = Object.keys(nodes);

  if (keys.length == 0)
  {
    return(<div>No Data</div>);
  }


var ti = null;

for (var i = 0; i < keys.length; i++)
{
  var node = nodes[keys[i]];
//  console.log(node);
  node.ti = new TreeItem("li", node.value, node.id);
  if (node.parent === null)
  {
    ti = node.ti;
  }
}


for (var i = 0; i < keys.length; i++)
{
  var node = nodes[keys[i]];
  if (node.parent !== null)
  {
    nodes[node.parent].ti.addChild(node.ti);
  }

}
//console.log(nodes);
//console.log(ti);

    return (
<div>
<a href={"/user/pelements?pid="+g_pid}>Project</a> <input type="button" value="Test" onClick={() => {
  helper.getFromServer(g_link+"?dw=testModel&pid="+this.pid+"&main="+this.main)
  .then(res => {
    alert(res.result);
  })
  .catch(err =>
  {
    alert(err);
  })

}}/><br/><br/>

<ul id="myUL"><div>{ti.render()}</div></ul>
<ModalForm 
  ref={component => this._modalForm = component}
 show={this.state.modalShowing} parent={this}>
   Description:<br/> <textarea rows="5" id="description"/>

</ModalForm>
</div>      
    )
}
}


export default ListTelements