import React from "react";

import * as THREE from 'three';
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import {FirstPersonControls} from "./fp1";
//import {TextureLoader} from "three/examples/jsm/loaders/TextureLoader";
/*
import tree1 from "./models/tree.obj"
import sign1 from "./images/tree.jpg";
import grass1 from "./images/grass1.jpg"
import path1 from "./images/path1.jpg"
import model1 from "./models/Xbot.glb"
*/

//import door1 from "./images/door1.png";

//let g_grassMaterial1;
//let g_pathMaterial1;

let mixer, actions, activeAction, randomWalker, g_grid;

const textureLoader = new THREE.TextureLoader();

function loadTexture(file)
{
    return new Promise((resolve, reject) => {
        textureLoader.load(file, function(texture) {
//            texture.wrapS = texture.wrapT = THREE.RepeatWrapping; 
            resolve(texture);
        })
    })
}


export class PlaneGeometry1 extends THREE.BufferGeometry {
    constructor(width = 1, height = 1, widthSegments = 1, heightSegments = 1) {
        super();
        this.type = 'PlaneGeometry';
        this.parameters = {
            width: width,
            height: height,
            widthSegments: widthSegments,
            heightSegments: heightSegments
        };
        const width_half = width / 2;
        const height_half = height / 2;
        const gridX = Math.floor(widthSegments);
        const gridY = Math.floor(heightSegments);
        const gridX1 = gridX + 1;
        const gridY1 = gridY + 1;
        const segment_width = width / gridX;
        const segment_height = height / gridY; //

        const indices = [];
        const vertices = [];
        const normals = [];
        const uvs = [];

//        var i = 0;

        for (let iy = 0; iy < gridY1; iy++) {
            const y = iy * segment_height - height_half;

            for (let ix = 0; ix < gridX1; ix++) {
//                var inc = 0;
//                i++;
                const x = ix * segment_width - width_half;
                vertices.push(x, -y, 0);
                normals.push(0, 0, 1);
                uvs.push(ix / gridX);
                uvs.push(1 - iy / gridY);
            }
        }

        for (let iy = 0; iy < gridY; iy++) {
            for (let ix = 0; ix < gridX; ix++) {
                const a = ix + gridX1 * iy;
                const b = ix + gridX1 * (iy + 1);
                const c = ix + 1 + gridX1 * (iy + 1);
                const d = ix + 1 + gridX1 * iy;
                indices.push(a, b, d);
                indices.push(b, c, d);
            }
        }

        this.setIndex(indices);
        this.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
        this.setAttribute('normal', new THREE.Float32BufferAttribute(normals, 3));
        this.setAttribute('uv', new THREE.Float32BufferAttribute(uvs, 2));
    }

}

export class Mobj
{
    children = [];
    name = "Need Model Type name!"
    displayName = "Need a display name!"

    icon = null;

    constructor(cellNumber)
    {
        this.cellNumber = cellNumber;

    }

    getOptionTag()
    {
        return <option value={this.name}>{this.displayName}</option>;
    }

    canEnterCell(cellNumber)
    {
        return true;
    }
    handleOption(which)
    {
        
    }
    createClip(mesh)
    {
 // create a keyframe track (i.e. a timed sequence of keyframes) for each animated property
            // Note: the keyframe track type should correspond to the type of the property being animated

            // POSITION
            var positionKF = new THREE.VectorKeyframeTrack( '.position', [ 0, 1, 2 ], [ 0, 0, 0, 30, 0, 0, 0, 0, 0 ] );

            // SCALE
            var scaleKF = new THREE.VectorKeyframeTrack( '.scale', [ 0, 1, 2 ], [ 1, 1, 1, 2, 2, 2, 1, 1, 1 ] );

            // ROTATION
            // Rotation should be performed using quaternions, using a QuaternionKeyframeTrack
            // Interpolating Euler angles (.rotation property) can be problematic and is currently not supported

            // set up rotation about x axis
            var xAxis = new THREE.Vector3( 1, 0, 0 );

            var qInitial = new THREE.Quaternion().setFromAxisAngle( xAxis, 0 );
            var qFinal = new THREE.Quaternion().setFromAxisAngle( xAxis, Math.PI );
            var quaternionKF = new THREE.QuaternionKeyframeTrack( '.quaternion', [ 0, 1, 2 ], [ qInitial.x, qInitial.y, qInitial.z, qInitial.w, qFinal.x, qFinal.y, qFinal.z, qFinal.w, qInitial.x, qInitial.y, qInitial.z, qInitial.w ] );


            // COLOR
            var colorKF = new THREE.ColorKeyframeTrack( '.material.color', [ 0, 1, 2 ], [ 1, 0, 0, 0, 1, 0, 0, 0, 1 ], THREE.InterpolateDiscrete );

            // OPACITY
            var opacityKF = new THREE.NumberKeyframeTrack( '.material.opacity', [ 0, 1, 2 ], [ 1, 0, 1 ] );

            // create an animation sequence with the tracks
            // If a negative time value is passed, the duration will be calculated from the times of the passed tracks array
            var clip = new THREE.AnimationClip( 'Action', 3, [ quaternionKF] );

            // setup the AnimationMixer
           this.mixer = new THREE.AnimationMixer( mesh );

            // create a ClipAction and set it to play
            var clipAction = this.mixer.clipAction( clip );
            clipAction.play();        

            console.log("play!")
    }


}


//const bridge = require("./models/bridge");
//const sign = require("./models/sign");

const loader1 = new OBJLoader();

let g_woodMaterial1 = new THREE.MeshBasicMaterial( {color: 0xffff00, side: THREE.DoubleSide} );

//let g_signMaterial1;
let g_bridgeMaterial1;
let g_roofMaterial1;

const textureloader = new THREE.TextureLoader();
/*
textureloader.load("/api/media/media7.png", function (texture) {

    g_signMaterial1 = new THREE.MeshBasicMaterial({
        map: texture,
        alphaTest: 0.5,
        transparent: true,
        side: THREE.FrontSide,

        //      overdraw: 0.5
    });

});
*/


export class PlaneGeometry2 extends THREE.BufferGeometry {
    constructor(vertices, indices, uvs) {
        super();
//        this.type = 'PlaneGeometry';
/*        
        this.parameters = {
            width: width,
            height: height,
            widthSegments: widthSegments,
            heightSegments: heightSegments
        }; */

        this.setIndex(indices);
        this.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
//        this.setAttribute('normal', new THREE.Float32BufferAttribute(normals, 3));
        this.setAttribute('uv', new THREE.Float32BufferAttribute(uvs, 2));
        this.computeVertexNormals();
    }

}


export function createObject(type, cellNumber)
{
    let obj;

    if (type.type)
    {
        // in this case "type" is actually {type: type, etc}
        obj = g_mam.modelTypes[type.type].create(cellNumber, type);

    }
    else
    {
        obj = g_mam.modelTypes[type].create(cellNumber);
    }
    g_mam.addModel(obj);
    return obj;

}
/*
class Interaction
{
    constructor(name)
    {
        this.name = name;
        this.alternatives = [];
    }
    addAlternative(description, result)
    {
        this.alternatives.push({description: description, result: result});
    }
    showAlternatives()
    {

    }
    checkActivation()
    {
        
    }


}
*/

class ModelManager
{
    constructor()
    {
        this.types = [];
        this.modelTypes = {};
        this.layout = [];
        this.layoutWidth = 0;
        this.layoutLength = 0;
/*
        this.addType(new bridge.Bridge());
        this.addType(new tree.Tree());
*/        
/*
        this.addType(new sign.Sign());
        this.addType(new sign.TextSign());
*/
        this.models = [];
    }

    addType(type)
    {
        //????OE??? Do we really need 2 lists?
        
        this.types.push(type)
        this.modelTypes[type.name] = type;
    }

    step(dt, cellNumber, currentCell)
    {
        for (var i = 0; i < this.models.length; i++)
        {
            this.models[i].step(dt, cellNumber, currentCell);
        }
    }
    canEnterCell(cellNumber)
    {

        for (var i = 0; i < this.models.length; i++)
        {
            if (this.models[i].canEnterCell(cellNumber) == false)
                return false;
        }
        return true;
    }


    addModel(model)
    {
        if (!model)
            return;

        console.log("adding model", model.name)
        this.models.push(model);
    }

    displayMessage(message)
    {
        this.parent.updateMessage(message);
    }

    showOptions(event, options, sender)
    {
        this.parent.showOptions(event, options, sender);
    }
    clearOptions()
    {
        this.parent.clearOptions();
    }
    handleOption(which, sender)
    {
        sender.handleOption(which);

    }
    test()
    {
//        alert("test!");
            this.models[0].doAnimation("open");
    }
    

    getModelOptions()
    {
        var options = [<option value="tree">Tree</option>];
        for (var i = 0; i < this.types.length; i++)
        {
            options.push(this.types[i].getOptionTag());
        }
        
        return options;

    }

    getGridImageInfo(i, layout)
    {
        let icons;
        var cname = "grid-item";
        if (layout[i] > 0 && layout[i] < 5)
        {
          cname = "grid-itemr";
        }
        else if (layout[i] == 5)
        {
//          icons = <img src={tree1} width="20" />
        }
/*        else if (layout[i] == 6)
        {
          icons = <img src={sign1} width="20" />
        }
        
        else if (layout[i] == 7)
        {
          icons = <img src={sign1} width="20" />
        } */
        else if (this.modelTypes[layout[i]])
        {
            icons = <img src={this.modelTypes[layout[i]].icon} width="20" />  
        }
/*        else if (layout[i] == "bridgeDoor")
        {
          icons = <img src={door1} width="20" />
        } */


        return [icons, cname];

    }

    createGridElement(type, document, gi, layout, which)
    {
/*        if (type === "tree")
        {
          var elem = document.createElement("img");
          elem.setAttribute("src", tree1);
          elem.setAttribute("width", "20");
          gi.appendChild(elem)
          layout[which] = 5;
        } */
/*        else if (type === "sign")
        {
          var elem = document.createElement("img");
          elem.setAttribute("src", sign1);
          elem.setAttribute("width", "20");
          gi.appendChild(elem)
          layout[which] = 6;
        }
        else if (type === "bridge")
        {
          var elem = document.createElement("img");
          elem.setAttribute("src", sign1);
          elem.setAttribute("width", "20");
          gi.appendChild(elem)
          layout[which] = 7;
        } */
        if (this.modelTypes[type])
        {
            var elem = document.createElement("img");
            elem.setAttribute("src", this.modelTypes[type].icon);
            elem.setAttribute("width", "20");
            gi.appendChild(elem)
            layout[which] = this.modelTypes[type].layoutId;
  
        }
/*        else if (type === "bridgeDoor")
        {
        } */
        else
            return false;

        return true;
    }

    isPath(c)
    {
        if (c === 1 || c === 2 || c === 3)
            return true;

        return false;
    }
    getFacingDir(cellNumber)
    {
//        cellNumber = cellNumber - 1; //OE??!! Fix this!!!  Make everything 0 based


        if (this.isPath(this.layout[cellNumber-1]))
        {
            return 0;
        }
        else if (this.isPath(this.layout[cellNumber+1]))
        {
//            console.log("mi", cellNumber)
            return Math.PI;
        }
        else if (this.isPath(this.layout[cellNumber-this.layoutWidth]))
        {
            return Math.PI/2;
        }
        else if (this.isPath(this.layout[cellNumber+this.layoutWidth]))
        {
            return Math.PI/2;
        }
//        else if (this.layout[cellNumber+this.width])

        return 0;

    }

}

var g_mam = new ModelManager;

export var mam = g_mam;

export async function initMaterials()
{
    return new Promise(async (resolve, reject) =>  {

/*        
        var texture = await loadTexture(grass1);
        g_grassMaterial1 = new THREE.MeshPhongMaterial({
            map: texture,
//            alphaTest: 0.5,
//            transparent: true,
//            side: THREE.FrontSide,
            side: THREE.DoubleSide,
            //      overdraw: 0.5
        });

        texture = await loadTexture(path1);

        g_pathMaterial1 = new THREE.MeshPhongMaterial({
            map: texture,
//            alphaTest: 0.5,
//            transparent: true,
            side: THREE.DoubleSide,
            //      overdraw: 0.5
        });
*/        
        
/*        
        textureLoader.load(grass1, function (texture) {
        g_grassMaterial1 = new THREE.MeshBasicMaterial({
            map: texture,
//            alphaTest: 0.5,
            transparent: true,
            side: THREE.DoubleSide,
            //      overdraw: 0.5
        });



        resolve(g_grassMaterial1);
*/
    })


}

function createMeshGroup(grid)
{

    var width = grid.width;
    var height = grid.height;
    var layout = grid.layout;
    var colors = grid.colors;
    grid.height = [];
    grid.height.length = layout.length;

    const seg = 2;
    var coordinatesList = [
        new THREE.Vector3(0, 0, 100),
        new THREE.Vector3(seg, 0, 100),
        new THREE.Vector3(seg, seg, -200),
        new THREE.Vector3(0, seg, 0),
      ];


//      console.log(coordinatesList)

      var mat = [];
      for (var i = 0; i < colors.length; i++)
      {
        mat.push(new THREE.MeshBasicMaterial({color:colors[i]}));

      }

      var groundColor = new THREE.MeshBasicMaterial({color: "green"});
      var pathColor = new THREE.MeshBasicMaterial({color: 0xc48c29});
/*
      var shape = new THREE.Shape(coordinatesList);
      console.log(shape)
      var geomShape = new THREE.ShapeBufferGeometry(shape);
*/
    var geom = new PlaneGeometry1(seg, seg);

    var group = new THREE.Group();

    var i = 0;
      var cx = width*seg/2;
      var cy = height*seg/2;

      grid.cx = cx;
      grid.cy = cy;
      grid.seg = seg;

      for (var y = 0; y < height; y++)
      {
          for (var x = 0; x < width; x++)
          {
              
            if (i >= layout.length)
              break;
            var c = layout[i];
            let m;

            let z;
            grid.height[i] = x*.3;
            
            if (grid.height && grid.height[i])
                z = grid.height[i];
            else
            {
                grid.height[i] = 0;
                z = 0;
            }


            if (c == 0 || c > 4 || isNaN(c))
            {
                if (c == 5)
                {
//                    loadObject("tree1", group,  new THREE.Vector3(x*seg - cx, cy-y*seg, z))
                    console.log("Error tree")
                }
/*                
                else if (c == 6)
                {
                    var p = new createObject("sign");

                    m = p.buildMesh();
                    m.position.x = x*seg - cx;
                    m.position.y = cy-y*seg;
                    m.position.z = z+1;
        
                    group.add(m);
                    i++;
                    continue;
                }
                */
                else if (g_mam.modelTypes[c])
                {
                    var p = new createObject(c, i);
                    m = p.buildMesh();
                    m.position.x = x*seg - cx;
                    m.position.y = cy-y*seg;
                    m.position.z = z+1;
        
                    group.add(m);
                    i++;
                    continue; 

                } 
                else if (g_mam.modelTypes[c.type])
                {
                    var p = new createObject(c, i);
                    m = p.buildMesh();
                    m.position.x = x*seg - cx;
                    m.position.y = cy-y*seg;
                    m.position.z = z+1;
        
                    group.add(m);
                    i++;
                    continue; 

                }
//                m = new THREE.Mesh(geom, g_grassMaterial1);
            }
            else
            {
    
//                m = new THREE.Mesh(geom, g_pathMaterial1);
            }
            
            m.position.x = x*seg - cx;
            m.position.y = cy-y*seg;
            m.position.z = z;

            i++;
//            console.log("position", m.position.y)
            group.add(m);

//            createGroundSegment(Ammo, physicsWorld, m);
          }
      }

    group.rotateX(-Math.PI/2);

    return group;


}


export function gridTo3(grid)
{
    if (!grid)
        grid = {width: 20, height: 20, color: "green"}
/*
    var grid = {};
    grid.width = width;
    grid.height = height;
    grid.color = "green";
*/
/*
    const layout = grid.layout;

    if (!layout)
        return newPlane(20, 20, null, "green");
*/

    g_grid = grid;
    return createMeshGroup(grid);

}
/*
function loadObject(name, scene, position)
{
    loader1.load( tree1, function ( model ) {

        console.log("loaded!!!!!")
        let object = model.children[0];
        object.position.x = position.x;
        object.position.y = position.y;
        object.position.z = position.z;

        object.scale.set(2, 2, 2)

        object.rotateX(Math.PI/2);

        scene.add(object);
    });
    
}
*/

let parent;

export function initAvatar(t1)
{
    parent = t1;
    g_mam.parent = t1;

    const loader1 = new GLTFLoader();
//    const t1 = this;
/*
    loader1.load( model1, function ( gltf ) {

        const states = [ 'Idle', 'Walking', 'Running', 'Dance', 'Death', 'Sitting', 'Standing' ];
        const emotes = [ 'Jump', 'Yes', 'No', 'Wave', 'Punch', 'ThumbsUp' ];
        
        mixer = new THREE.AnimationMixer( gltf.scene );

        actions = {};

        const animations = gltf.animations;

        for ( let i = 0; i < animations.length; i ++ ) {

            const clip = animations[ i ];
            const action = mixer.clipAction( clip );
            actions[ clip.name ] = action;

            if ( emotes.indexOf( clip.name ) >= 0 || states.indexOf( clip.name ) >= 4 ) {

                action.clampWhenFinished = true;
                action.loop = THREE.LoopOnce;

            }

        }

        console.log(actions)

        activeAction = actions[ 'walk' ];
        activeAction.play();

        console.log("loaded!!!!!")

        t1.scene.add(gltf.scene);

        randomWalker = new RandomWalker(gltf.scene)

//        t1.avatar = gltf.scene;
        t1.avatar = new THREE.Object3D();
        t1.scene.add(t1.avatar);


        t1.avatar.position.x = 0; 
        t1.avatar.position.y = 0;
        t1.avatar.position.z = 5-t1.grid.cy; 

        if (t1.grid.start)
        {
            const trans = new Transformer(t1.grid.width, t1.grid.height);
            const c = trans.getTranslationCell(t1.grid.start)
//            console.log(c);
            t1.avatar.position.x = c[0]; 
            t1.avatar.position.y = 0;
            t1.avatar.position.z = c[1];
    
            gltf.scene.position.x = c[0];
            gltf.scene.position.y = 0;
            gltf.scene.position.z = c[1];

        }

        t1.avatar.position.z = 0; 

        gltf.scene.position.z = 0;

        t1.controls = new FirstPersonControls( t1.avatar, t1.mount );
//        this.controls.movementSpeed = 10; // How fast the player can walk around
//        this.controls.lookSpeed = 10; // How fast the player can look around with the mouse
t1.controls.lookVertical = false; // Don't allow the player to look up or down. This is a temporary fix to keep people from flying
t1.controls.noFly = true; // Don't allow hitting R or F to go up or down


    });
*/
}

var g_currentCell = 0;

export function updateCamAv(t1, elapsed)
{
    if (t1.avatar && t1.controls)
    {

        var x = t1.avatar.position.x;
        var y = t1.avatar.position.y;
        var z = t1.avatar.position.z;

        var speed = .03;

        t1.controls.update(speed*elapsed/10);

//        this.camera.setRotationFromEuler(oldRotation);

        const c = getGridCell(t1.grid,  t1.avatar.position.x,  t1.avatar.position.z);

        if (!g_mam.canEnterCell(c)
            || !isOnLine(t1.grid, t1.avatar.position.x, t1.avatar.position.z))
        {

            t1.avatar.position.set(x, y, z);
        }
        else
        {
            // successful & update
            g_currentCell = c;
            x = t1.avatar.position.x;
            z = t1.avatar.position.z;
        }


        g_mam.step(elapsed, c, g_currentCell);


        t1.avatar.position.y = t1.grid.height[c]+.3;

//        this.camera.position.y = oldPosition.y;
//        this.camera.position.z = oldPosition.z;

        const v1 = new THREE.Vector3(0, 0, 1).applyQuaternion(t1.avatar.quaternion);

        t1.camera.quaternion.copy(t1.avatar.quaternion);
            
        t1.camera.position.copy(t1.avatar.position).add(v1.multiplyScalar(1.5));
        t1.camera.position.y += 1.1;

        if ( mixer ) 
            mixer.update(elapsed/1000);
        
        if (randomWalker)
            randomWalker.update(elapsed);

    }


}

class RandomWalker
{
    constructor(model, layout)
    {
        this.model = model;

    }



    update(dt)
    {
        const v1 = new THREE.Vector3(0, 0, dt/1000).applyQuaternion(this.model.quaternion);

        
        var p1 = new THREE.Vector3();
        p1.copy(this.model.position);
        p1.add(v1);

//        console.log(p1)

        if (isOnLine(g_grid, p1.x, p1.z))
        {
            this.model.position.copy(p1);
            const c = getGridCell(g_grid, p1.x, p1.z);
            this.model.position.y = g_grid.height[c]+.3;            
        }
        else
        {
            var d = 1;
            if (Math.random() > .5)
                d = -1;
            this.model.rotateY(d*Math.PI/4);
        }


    }

}

class Transformer
{

/*
    Grid goes from -width to width, -height to height
*/
    constructor(width, height)
    {
        this.centerX = width;
        this.centerY = height;
    }

    getTranslation(x, y)
    {
        return ([x-this.centerX, this.centerY-y]);

    }

    getTranslationCell(cell)
    {
        const x = cell%this.centerX;
        const y = cell/this.centerX;

        return this.getTranslation(x, y);
    }

}

function getGridCell(grid, x1, y1)
{
/*    
    var y = Math.floor((y1+grid.cy)/grid.seg)+1;
    var x = Math.floor((x1+grid.cx)/grid.seg+grid.seg/2);
*/

    var [x, y] = transformCoords(x1, y1, grid);

    var i = y*grid.width+x;
    if (i < 0) 
        i = 0;
    if (i >= grid.height.length)
        i = grid.height.length-1;
//    console.log(i);
    return i;
}

function transformCoords(x1, y1, grid)
{
    var y = Math.floor((y1+grid.cy)/grid.seg-.5)+1;
    var x = Math.floor((x1+grid.cx)/grid.seg+.5);

    return [x, y];
}

export function isOnLine(grid, x1, y1)
{
/*    
    var y = Math.floor((y1+grid.cy)/grid.seg)+1;
    var x = Math.floor((x1+grid.cx)/grid.seg);
*/    

    var [x, y] = transformCoords(x1, y1, grid);
/*

    var y = Math.floor((y1+grid.cy)/grid.seg-.5)+1;
    var x = Math.floor((x1+grid.cx)/grid.seg+.5);
*/

    if (x < 0 || y < 0)
    {
//        console.log("less than zero", x, y, y1, grid.cy)
        return false;
    }
    if (x >= grid.width ||  y >= grid.height)
    {
//        console.log("greater than", x, y)
        return false;
    }

    var i = y*grid.width+x;

    if (i == 259)
        parent.updateMessage("Over 259!")

//    console.log(x, y, x1, y1, i, grid.layout[i])

    if (i < 0)
    {
//        console.log("less than zero", x1, y1, i, grid.layout.length);
        return false;
    }
    if (i >= grid.layout.length)
    {
//        console.log("greater than length", x1, y1, i, grid.layout.length);
        return false;
    }

    if (grid.layout[i] != 0)
    {
//        console.log("true", i, x, y, grid.width, grid.height)
        return true;
    }

//    console.log(i, x, y, grid.width, grid.height)
    return false;

}


export class simShape
{
    constructor(nPoints, radii)
    {
        
    }


}

export function get3Geometry(config)
{

    var geometry = new THREE.BoxGeometry( 1, 1, 1 );
    var material = new THREE.MeshBasicMaterial( config );
    return new THREE.Mesh( geometry, material );
 
}