//import { ReactMic } from 'react-mic';
//import './record.css';
//import Dropzone from 'react-dropzone'

const React = require("react")
//import { render } from "react-dom";
//import {callUrl} from "./helper"
//const Space = require("./comp_misc").compSpace
const config = require('./config.js').CONFIG
//const Modal = require('react-modal')
const helper = require('./helper');
const TextPrompt = require("./comp_misc").textPrompt
//const CompList = require("./comp_misc").compList
//const CompListItem = require("./comp_misc").compListItem
const TextAreaPrompt = require("./comp_misc").textAreaPrompt;
const moment = require("moment");
const Modal = require("./comp_misc").modal;

// OE??? Note the arrow function and bind issue here:
// https://reactjs.org/docs/faq-functions.html


class ListLine extends React.Component { 

//  <tr><td>{j1[i].user.name}</td><td>{j1[i].body}</td><td>{edit}</td></tr>

  componentDidMount()
  {
    this.setState({body1: this.props.body, body: this.props.body})

  }


  render() {
let link;
if (this.props.filename)
  link = <a href={config.back+"/media2/pelements/"+this.props._id} download={this.props.filename}>{this.props.filename}</a>
  if (this.props.description && this.props.description.startsWith("http"))
  {
    link = <a href={this.props.description}>{this.props.name}</a>
  }
  else if (this.props.type == "loop")
  {
    link = <a href="/api/static/playloop.html">Listen</a>

  }
    return (
      <tr><td>{this.props.name}</td>
      <td>{link}</td>
      <td>{this.props.type}</td>
      <td>{this.props.order}</td>
      <td>{this.props.date}</td>
      <td>
        <input type="button" value="Edit" className="btn btn-primary" onClick={() => this.props.showModal(this.props._id, this.props.name, this.props.type, this.props.order, this.props.description, this.props.type)}/>

      </td></tr>
    )

  }

}

class ListCTypes extends React.Component {
  constructor(props) {
    super(props);
var pid = helper.parseQuery(document.location.search).pid

  this.state = {
    rows: null,
    name: "",
    description: "",
    _id: null,
  };
    this.addRow = this.addRow.bind(this)
    this.showModal = this.showModal.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)

  }

addRow(doc)
{

  console.log(doc)

}

fetchRows()
{
    helper.getFromServer("/user/ctypes")
    .then(res => {
    var j1 = res.result;
    var rows1 = [];
    if (res.code === 200)
    {
      console.log("got it!");
      for (var i = 0; i < j1.length; i++)
      {

        var id = j1[i]._id;
        rows1.push(<ListLine key={i} description={j1[i].description} name={j1[i].name} _id={id} showModal={this.showModal} />);
      }
    }
    else
      alert("error fetching rows ");
    console.log(this);
    console.log(rows1);
    this.setState({rows: rows1})
    })
    .catch(err => {
        alert(err)
    })

}

deleteItem()
{
  helper.postToServer("/user/ctypes", {dw:'del', _id: this.state._id})
  .then(res =>{
    this.hideModal();
    this.fetchRows();
  })
  .catch(err => {
    alert(err);
  })
}

componentDidMount() 
{
  this.fetchRows()

}

saveItem()
{
  
  helper.postToServer("/user/ctypes", {dw:'update', _id: this.state._id, name: this.state.name, description: this.state.description})
  .then(res =>{
    if (res.code !== 200)
    {
      console.log(res);
      alert("Error saving");
    }
    this.hideModal();
    this.fetchRows();
  })
  .catch(err => {
//    console.log(err);
    alert(err);
  })
}



showModal(_id, name, type, order, description, type1)
{
//  this.setState()  
//console.log(_id)
  this.setState({ show: true, _id: _id, name: name, description: description});
};

hideModal = () => {
  this.setState({ show: false });
};


addItem()
{

    helper.postToServer("/user/ctypes", {dw: 'add'})
    .then(res => {
        if (res.code !== 200)
            alert(res.result)
//        console.log(res.result)
        this.fetchRows()
    })
    .catch(err =>{
        alert("Server says: "+err)
    })

}

handleInputChange(event) {

  const target = event.target;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  const name = target.name;

  console.log(name)
  this.setState({
    [name]: value
  });

}

  render() {
    document.title = "Transtainable CTypes"

    if (this.state.rows === null) {
return(
      <div>Working on it CTypes</div>);
    } else {
//      const showRecord = (this.state.type=="dialog") ? "display-block" : "display-none";

//      var showRecord = "display-none";

//console.log(this.state.lines)    
return(

<div className="container">
<br/>
CTypes
  <table className="table">
	<tbody>
    
	{this.state.rows}
	</tbody>
  </table>

  <Modal show={this.state.show} handleClose={this.hideModal}>
          <br/>
          <div><TextPrompt name="name" title="Name" onChange={this.handleInputChange} value={this.state.name}/></div>  
          <div><TextAreaPrompt name="description" title="Description" onChange={this.handleInputChange} value={this.state.description}/></div>  

            <button type="button" className="btn btn-primary" onClick={this.saveItem.bind(this)}>Save</button>&nbsp;
          <button type="button" className="btn btn-primary" onClick={this.deleteItem.bind(this)}>Delete</button>&nbsp;
         
        </Modal>

        <button type="button" className="btn btn-primary" onClick={this.addItem.bind(this)}>Add</button>&nbsp;

</div>

)
}
  }
}

export default ListCTypes
