import React from "react";
//import { getSqlDateString } from "../../server/helper";
const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const TextPrompt = require("../comp_misc").textPrompt;
const misc = require("../comp_misc");

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/user/form_feed";

class Form1 extends React.Component {
  
  constructor(props)
  {
    super(props);
    var q = helper.parseQuery();
    this.state = {
      body: null,
      id: q.id,
      dw: q.dw ? q.dw : "get",
    };
  }



fillList(filter)
{

//  console.log(this.state.projectId)

  helper.getFromServer(g_link+`?dw=${this.state.dw}&id=${this.state.id}`)
  .then(res => {
    console.log(res)
//    getSqlDateString()
    var params = {showForm: false, spec: res.result, options: res.options};
    this.setData(res.result, params)
    this.setState(params);
  })
  .catch(err => {
    helper.handleError(err, this);
  })


}
 componentDidMount() {
  this.fillList()  
  }


  saveItem()
  {
//    helper.postToServer(g_link, {dw: "update", id: this.state.id, email: this.state.email, name: this.state.name}, true)
    var dw = "update";
    if (this.state.spec.type == "import")
      dw = "import";

    var params = {dw: dw, id: this.state.id};
    this.getData(params);
    helper.postToServer(g_link, params, true)
.then(res => {
      this.fillList();
    })
    .catch(err => {
      helper.handleError(err, this);
    })

  }

  
  deleteItem()
  {

    helper.postToServer(g_link, {dw: "del", id: this.state.id}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }

  
  hic(event) {

    const target = event.target;
    const name = target.name;

    let value;
//    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.type === 'checkbox')
    {
      value = this.state[name];
      if (!value)
        value = [];
        // assumes DOM won't feed us dupes
        var vint = parseInt(target.value);

      if (target.checked)
      {
        value.push(vint);
      }
      else
      {
        var index = value.indexOf(vint);
        if (index !== -1) value.splice(index, 1);
      }
    }
    else
    {
      value = target.value;
    }
    this.setState({
      [name]: value
    });
  
  }

  getData(params)
  {
    const layout = this.state.spec.layout;
    const data = this.state.spec.data;

    layout.map(item => {
//      form.push(<>{item.type}<br/></>)
        params[item.name] = this.state[item.name];
    })
    
  }

  setData(spec, params)
  {
    const layout = spec.layout;
    const data = spec.data;

    layout.map(item => {
//      form.push(<>{item.type}<br/></>)
        params[item.name] = data[item.name];
    })
    
  }

  buildForm()
  {
    var form = [];
    form.push(<>{this.state.spec.name}</>);

    const layout = this.state.spec.layout;
    layout.map(item => {
//      form.push(<>{item.type}<br/></>)
        misc.addField(this, form, item);
    })
    var dw = "Save";
    if (this.state.spec.type === "import")
      dw = "Import";

    form.push(<button onClick={this.saveItem.bind(this)}>{dw}</button>)
    return form;
    
  }

  render() {
// https://www.w3schools.com/howto/howto_css_two_columns.asp
    
document.title = "Form";
if (!this.state.spec)
{
  return <div></div>
}
var form = this.buildForm();

return(

<div className="container">
<div className="row">
<div className="column-left">
  <br/>
Left!
</div>
<div className="column-right">
  <br/>
Info:<br/><br/>
{form}
<br/>
</div>
</div>
</div> 

)

}
}

export default Form1
