export const back = window.location.origin+"/api";
export const client = true

/*
var back1 = 'https://localhost:3010/api';
var client1 = true;
var name1 = "Omnimodel"
var hostname = window.location.hostname;
if (hostname.indexOf("transtainable.com") != -1)
{
    back1 = "https://www.transtainable.com:/api"
    client1 = false;
    name1 = "Transtainable"
}
else if (hostname.indexOf("omnimodel.com") != -1)
{
    back1 = "https://www.omnimodel.com:/api"
    client1 = false;
    name1 = "Omnimodel"
}
else if (hostname.indexOf("cyberhoff.com") != -1)
{
    back1 = "https://www.cyberhoff.com:/api"
    client1 = false;
    name1 = "Cyberhoff"

}


export const back = back1;
export const client = client1;
export const name = name1;
*/