"use strict"

import React from "react";

const helper = require("./helper");
const Modal = require("./comp_misc").modal;

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/user/content";


class FrameForm extends React.Component {
  
  constructor(props)
  {
    super(props);
    var q = helper.parseQuery();
    var id = q["id"];

    this.state = {
      id: id,
      side: q["side"]
    };
  }




fillList()
{
    helper.getFromServer(g_link+"?id="+this.state.id+"&side="+this.state.side)
  .then(res => {
    console.log(res.result)
    this.setState({showForm: false, props: res.result});
  })



}
 componentDidMount() {
  this.fillList()  
  }

    

  render() {
document.title = "Media";

let listItems;
let spinner;
if (!this.state.props)
{
  spinner = <div class="d-flex justify-content-center">
  <div class="spinner-border spinner text-primary" role="status">
      <span class="sr-only">Loading123</span>
  </div>
</div>;
    return spinner;
}
let frame;

if (this.state.props.ext == "pdf")
{
  frame = <div class="container mx-0 px-0">
  <div class="embed-responsive embed-responsive-21by9">
    <iframe class="embed-responsive-item" src={"/api/media2/"+this.state.props.link} allowfullscreen></iframe>
  </div>
</div>

}
else
{
  frame = <iframe style={{width: "500px", height: "500px"}} src={"/api/media2/"+this.state.props.link}/>
 


}
return(
<div>


    <div class="container my-5">
        
{/*<!-- course title (logo and name) -->*/}
        <div class="container d-flex justify-content-end mb-5">
            <a href={`media?pid=${this.state.props.pid}`}>Back to Media</a>
{/*            
            <div class="dropdown">
                  <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Go to another course
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" href="#">Action</a>
                  </div>
            </div>
*/}            
        </div>
        
        <div class="data-container mb-4">{/*<!-- this is fixed text no dynamics -->*/}
            <h5 class="text-primary">Description: {this.state.props.description}</h5>
        </div>
        
{/*<!-- course item object embed start for pdf viewer -->*/}
{frame}
    </div>



 
</div>

)
}
}


export default FrameForm
