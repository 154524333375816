"use strict"

const React = require("react")
const Component = React.Component
const helper = require("./helper");

class ResetPass extends Component
{

  constructor(props)
  {
    super(props);
    this.state = {
      password:null,
      email:null,
      error:null,
      success:null,
    };
  }

  doReset()
  {
    var email = document.getElementById("email").value;
//    alert(email);

    helper.postToServer("/rpass1", {email: email}, true)
    .then(res => {
//      console.log(res);
//      this.setState({showError:"show", error: ""});
//      document.location = "/admin/companies"
    this.setState({error: null, success: true});
    
    })
    .catch(err => {
      this.setState({showError:"show", error: err});

    })
  }

  handleInputChange(event) {
    const target = event.target;
    if (target === undefined)
    {
      this.setState({
        date: event
          });
      return
    }
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });

  }

render()
{

  var error1 = <div class="alert alert-danger alert-dismissible fade show" role="alert">
      An error has occurred.
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
      </button>
  </div>;

  if (!this.state.error)
    error1 = <div></div>;

    var success = null;

    if (this.state.success)
    {
      error1 = <div></div>;
      success = <div class="alert alert-success alert-dismissible fade show" role="alert">
    Password reset email sent. Check your inbox (and spam) folder.
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
    </button>
</div>;
    }

  



    return(
        <div>
        <div class="login-box">
            <h2 class="text-center text-warning mb-4">Password Reset</h2>
            
      {error1}
      {success}
        

            <div class="form-group">
              <label for="emailAddress"><i class="fa fa-envelope text-warning" aria-hidden="true"></i> Email address</label>
              <input type="email" class="form-control" id="email" placeholder="Enter email"/>
              <small id="emailHelp" class="form-text text-muted">Enter your assigned email address and click reset button</small>
            </div>
            <button onClick={this.doReset.bind(this)} class="btn btn-warning btn-lg login-btn">Reset My Password</button>
      </div>
    <div class="login-footer">
        <p>OmniModel</p>
    </div>
    </div>
)

}    
}

export default ResetPass;
