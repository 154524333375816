import React, { useState } from 'react'
//import styles from './styles.module.css'

export const Drawer = (props) => {
  return <div style={{position: "relative", display: "inline-block"}}>
  <span style={{fontSize:"30px",cursor:"pointer"}} onClick={() => {
    
    var x = document.getElementById("Demo1");
if (x.className.indexOf("w3-show") == -1) {
x.className += " w3-show";
} else { 
x.className = x.className.replace(" w3-show", "");
}
  
  }}>&#9776;</span>
  <div id="Demo1" style={{display: "none", position: "absolute"}}  className="w3-dropdown-content w3-bar-block w3-border w3-hide">
  <div>
      {props.children}
</div></div></div>
}

export const getLocalDate = function(d1)
{
  return d1;
}

export const Section = (props) => {

  return <div>
    {props.children}
  </div>
}

export const Page = (props) => {

  return <div><center>
    {props.children}
    </center>
  </div>
}


export const SectionList = (props) => {

  const [count, setCount] = useState(0);


  let list;
  if (props.list)
  {
    list = [];
    props.list.map(item => {
      list.push(<a href="#" onClick={() => {
        if (props.onSelect)
        {
          props.onSelect(item);
        }
//        alert(item) 
      }
      }>{item}</a>);
      list.push(<br/>);
    })
    
  }
  else
  {
    list = <div>1.<br/>2.<br/>3.<br/></div>;

  }
  return <div style={{textAlign: "left"}}>
    {list}
  </div>


}

/*
export class SectionList1 extends React.Component
{

render()
{
  let list;
  if (this.props.list)
  {
    list = [];
    this.props.list.map(item => {
      list.push(<a href="#" onClick={() => {
        if (this.props.onSelect)
        {
          this.props.onSelect(item);
        }
//        alert(item) 
      }
      }>{item}</a>);
      list.push(<br/>);
    })
    
  }
  else
  {
    list = <div>1.<br/>2.<br/>3.<br/></div>;

  }
  return <div style={{textAlign: "left"}}>
    {list}
  </div>


}
}

export const SectionList = SectionList1;
*/


class select1 extends React.Component
{

  componentDidMount()
  {
    
  }
  render()
  {
    var list = [];
//    list.push(<option>Please Select</option>)

    if (this.props.options) {
    this.props.options.map(item => {
      list.push(<option key ={item.id} value={item.id}>{item.name}</option>);
    })
  }
    return (<>
    <select name={this.props.name} value={this.props.value} onChange={this.props.onChange}>
      {list}
    </select>
    </>);
  }

}

export const Select = select1;



const currentDate = new Date();

const categories = [
  { name: "Last 5 Years", dateBegin: findDate(1, 5*12), dateEnd: findDate() },
  { name: "All", dateBegin: null, dateEnd: null},
  { name: "Custom", dateBegin: null, dateEnd: null },
  { name: "Today", dateBegin: findDate(), dateEnd: findDate() },
  { name: "Yesterday", dateBegin: findDate(1), dateEnd: findDate(1) },
  { name: "This Week", dateBegin: findDate(currentDate.getDay()), dateEnd: findDate() },
  { name: "Last Week", dateBegin: findDate(7 + currentDate.getDay()), dateEnd: findDate(7 - currentDate.getDay()) },
  { name: "Past 7 Days", dateBegin: findDate(7), dateEnd: findDate() },
  { name: "This Month", dateBegin: findDate(currentDate.getDate() - 1), dateEnd: findDate() },
  { name: "Last Month", dateBegin: findDate(1, 1), dateEnd: findDate(currentDate.getDate()) },
  { name: "Past 30 Days", dateBegin: findDate(30), dateEnd: findDate() },
  { name: "Past 6 Months", dateBegin: findDate(1, 6), dateEnd: findDate() },
  { name: "This Year", dateBegin: findDate(1, currentDate.getMonth()), dateEnd: findDate() },
  { name: "Past 12 Months", dateBegin: findDate(1, 12), dateEnd: findDate() },
];

function findDate(dayBuffer, monthBuffer) {
    let curr = new Date();

    if (dayBuffer) {
      if (monthBuffer) {
        curr.setDate(dayBuffer);
        curr.setMonth(curr.getMonth() - monthBuffer);
      } else {
        curr.setDate(curr.getDate() - dayBuffer);
      }
    }

    return curr.toISOString().substr(0,10);
}


class datePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: this.props.cat? this.props.cat : 0,
      dateBegin: this.props.startDate? this.props.startDate : categories[0].dateBegin,
      dateEnd: this.props.endDate? this.props.endDate: categories[0].dateEnd,
    };
    this.props.parent.setState({date1:this.state.dateBegin, date2: this.state.dateEnd})
    if (this.props.setDateFilter)
    {
      this.setDateFilter = this.props.setDateFilter.bind(this);
      this.setDateFilter(this.state.dateBegin, this.state.dateEnd, this.state.category);
    }
  }

  componentDidMount() {
    if (this.setDateFilter)
    {
      this.setDateFilter(this.state.dateBegin, this.state.dateEnd, this.state.category);

    }
  }

  handleSelect(event) {
    const value = event.target.value;

    console.log(value)
    // Future proofing for potental cases
    if (!categories[value].dateBegin) {
      document.getElementById("dateBegin").value = "";
    }
    if (!categories[value].dateEnd) {
      document.getElementById("dateEnd").value = "";
    }

    // Callback to set the dates in the parent once the initial setState function is finished
    this.props.parent.setState({date1: categories[value].dateBegin, date2: categories[value].dateEnd });
    this.setState({ category: value, dateBegin: categories[value].dateBegin, dateEnd: categories[value].dateEnd }, () => {
      if (this.setDateFilter)
        this.setDateFilter(this.state.dateBegin, this.state.dateEnd, this.state.category);
    });
  }

  // Man, callbacks really suck, don't they?
  // (Closure just so only silly callback will work correctly)
  checkDate(name, oldDate) {
    return () => {
      if (Date.parse(this.state.dateBegin) > Date.parse(this.state.dateEnd)) {
        this.setState({[name]: oldDate});
        alert("Invalid Date Range");
      } else {
        this.setState({category: 2})
        this.props.parent.setState({date1: this.state.dateBegin, date2: this.state.dateEnd });

        if (this.setDateFilter)
          this.setDateFilter(this.state.dateBegin, this.state.dateEnd);
      }
    }
  }

  handleChange(event) {
    const oldDate = this.state[event.target.name];

    this.setState({[event.target.name]: event.target.value}, this.checkDate(event.target.name, oldDate));
    
  }

  handleSubmit(event) {
    event.preventDefault();
    
    this.setDateFilter(this.state.dateBegin, this.state.dateEnd, this.state.category);
  }

  render() {
    return (
      <div>
        Date Range:
        <form onSubmit={this.handleSubmit.bind(this)}>
          <label>
            Category:{" "}
            <select
              value={this.state.category}
              onChange={this.handleSelect.bind(this)}
            >
              {categories.map((item, index) => (
                <option key={index} value={index}>
                  {item.name}
                </option>
              ))}
            </select>
          </label>
          <label>
            {" "}Start Date:{" "}
            <input
              id="dateBegin"
              type="date"
              name="dateBegin"
              onChange={this.handleChange.bind(this)}
              value={this.state.dateBegin}
            />
          </label>
          <label>
            {" "}End Date:{" "}
            <input
              id="dateEnd"
              type="date"
              name="dateEnd"
              onChange={this.handleChange.bind(this)}
              value={this.state.dateEnd}
            />
          </label>
          {/*
            {" "}
            <input type="submit" value="Set" />
          */}
        </form>
      </div>
    );
  }
}

export const DatePicker = datePicker
