import React from "react";
import ReactMarkdown from 'react-markdown'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const TextPrompt = require("../comp_misc").textPrompt;
const misc = require("../comp_misc");

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/user/project_costs";
var g_pid;


function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    <td><a href={`/user/project_costs?pid=${g_pid}&cid=${props.item.id}`}>{props.item.name}</a></td>
    <td><a href={`dev?id=${props.item.id}`}>Dev</a></td>
    <td>{props.item.version}</td>
    <td>{props.item.code}</td>
    <td>{props.item.quantity}</td><td>{props.item.unit}</td><td>{helper.formatMoney(props.item.calculatedQuantity)}</td>
    <td></td>
    <td>{props.item.children.substring(0, 50)}</td>
  <td align="center"><button type="button" class="btn btn-outline-primary" onClick={() =>
    {
    props.parent.showModal(props.item) }
  }><i class="fa fa-edit mr-2" aria-hidden="true"></i>Edit</button></td>  

</tr>

}

class ModalList1 extends React.Component {
  
  constructor(props)
  {
    super(props);
    var q = helper.parseQuery();
    g_pid = q.pid;
    this.state = {
      show: false,
      rows: null,
      name:null,
      error:null,
      cid: q.cid,
      projectId: q.pid,
            
    };
  }


  filter(event)
  {
    const target = event.target;
//    const name = target.name;
    var value = target.value;

    this.fillList(value);

  }



fillList(filter)
{
  var rows1 = [];

  var cid = this.state.cid ? this.state.cid:"";
  var pid = this.state.projectId;
  helper.getFromServer(g_link+`?dw=get&projectId=${pid}&costId=${cid}`)
  .then(res => {
    this.setState({showForm: false, rows: res.result, options: res.options});
  }).catch(err => {
    alert(err)
  })



}
 componentDidMount() {
  this.fillList()  
  }

  showModal(item)
  {
//      var code = item.code ? item.code: '';

//    this.setState({showForm: true, image: "/api/media/logo"+item.id+".png", id: item.id, name: item.name, email: helper.checkNull(item.email), error: ""});
    this.setState({showForm: true, id: item.id, name: item.name,code: helper.checkNull(item.code), 
       quantity: helper.checkNull(item.quantity), children: item.children, version: item.version, error: ""});
    
}

  hideModal()
  {
    this.setState({showForm: false});
  }

  saveItem()
  {
    
//    helper.postToServer(g_link, {dw: "update", id: this.state.id, email: this.state.email, name: this.state.name}, true)
    helper.postToServer(g_link, {dw: "update", id: this.state.id, name: this.state.name,code: this.state.code, costId: this.state.cid,
    quantity: this.state.quantity, unit: this.state.unit, children: this.state.children, version: this.state.version}, true)
.then(res => {
      this.fillList();
    })
    .catch(err => {
      this.setState({error: err});
    })

  }
  addItem()
  {

    helper.postToServer(g_link, {dw: "add", projectId:this.state.projectId, costId: this.state.cid, code: this.state.code}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }

  deleteItem()
  {

    helper.postToServer(g_link, {dw: "del", id: this.state.id}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }

  
  hic(event) {

    const target = event.target;
    const name = target.name;

    let value;
//    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.type === 'checkbox')
    {
      value = this.state[name];
      if (!value)
        value = [];
        // assumes DOM won't feed us dupes
        var vint = parseInt(target.value);

      if (target.checked)
      {
        value.push(vint);
      }
      else
      {
        var index = value.indexOf(vint);
        if (index !== -1) value.splice(index, 1);
      }
    }
    else
    {
      value = target.value;
    }
    this.setState({
      [name]: value
    });
  
  }

  onFile() {

    var file = document.getElementById(undefined).files[0];
  
    var formData = new FormData();
    formData.append('file', file);
    formData.append('id', this.state.id)
    formData.append("dw", "saveFile")
    
    fetch(config.back+g_link+"_up", {
      method: 'POST', credentials: "same-origin",
      body: formData
    }).then(response => response.json())
    .then(res => {
      
      this.setState({image: "/api/media/!!upload_tag!!"+this.state.id+".png?"+new Date().getMilliseconds(),
    alert: "File was uploaded"});
    })
    .catch(error => { 
      alert(error);
    });
   //.then(response => console.log('Success:', response));
    
  }
    

  render() {
document.title = "Project Costs";
let listItems;
//

if (this.state.rows)
{

  

  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

}
else
{
  return (<div></div>)
}
return(

<div className="container">
<a href={`/user/project_costs?pid=${g_pid}`}>{this.state.options.projectName}</a> 
<misc.Space />
<misc.Space />
<misc.Space />
<a href={`/user/project_costs?pid=${g_pid}&cid=${this.state.options.upid}`}>Up</a> {this.state.options.costName}
<br/><br/>
<button onClick={this.addItem.bind(this)}>Add Item</button> 
<misc.Space />
<button onClick={() =>{
    helper.postToServer(g_link, {dw: "recalc", projectId:this.state.projectId, costId: this.state.cid}, true)
    .then(res => {
      this.setState({errors: res.result})
//      this.fillList()
    })
    .catch(err => {
      console.log(err)
      helper.handleError(err)
    })

}}>Recalculate</button><misc.Space /><button onClick={() => {

  
/*  
  confirmAlert({
    title: 'Confirm to submit',
    message: 'Are you sure to do this.',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ]
  });
*/
}}>Check codes</button><br/>
<ReactMarkdown>{this.state.errors}</ReactMarkdown>

<table className="user-table">
    <td>Name</td>
    <td>Dev</td>
    <td>Version</td>
    <td>Code</td>
    <td>Cost</td>
    <td>Unit</td>
    <td>Calculated Cost</td>
    <td>Total</td>
    <td>Children</td>
	<tbody>
    
  {listItems}
  
	</tbody>
  </table><br/>

  Total: {helper.formatMoney(this.state.options.total)}<br/>
  <Modal show={this.state.showForm} handleClose={this.hideModal.bind(this)}>
          <br/>
          <div><TextPrompt name="name" title="name" onChange={this.hic.bind(this)} value={this.state.name}/></div>
          <div><TextPrompt name="version" title="Version" onChange={this.hic.bind(this)} value={this.state.version}/></div>
<div><TextPrompt name="code" title="Code" onChange={this.hic.bind(this)} value={this.state.code}/></div>
<div><TextPrompt name="quantity" title="Quantity" onChange={this.hic.bind(this)} value={this.state.quantity}/></div>
<div><TextPrompt name="unit" title="Unit" onChange={this.hic.bind(this)} value={this.state.unit}/></div>
<div><TextPrompt name="children" title="Children" onChange={this.hic.bind(this)} value={this.state.children}/></div>

        <button type="button" className="btn btn-primary" onClick={this.saveItem.bind(this)}>Save</button>&nbsp;
        <button type="button" className="btn btn-primary" onClick={this.deleteItem.bind(this)}>Delete</button>&nbsp;

</Modal>

</div>
)

}
}

export default ModalList1
