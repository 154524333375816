import React from "react";
const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const TextPrompt = require("../comp_misc").textPrompt;
const misc = require("../comp_misc");

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/user/feed";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    <td><a href={`form_feed?id=${props.item.id}`}>{props.item.body.substring(0, 40)}</a></td>
    <td><a href={`form_feed?dw=import&id=${props.item.id}`}>Import</a></td>
  <td align="center"><button type="button" class="btn btn-outline-primary" onClick={() =>
    {
    props.parent.showModal(props.item) }
  }><i class="fa fa-edit mr-2" aria-hidden="true"></i>Edit</button></td>  

</tr>

}

class ModalList1 extends React.Component {
  
  constructor(props)
  {
    super(props);
    var q = helper.parseQuery();
    this.state = {
      show: false,
      rows: null,
      name:null,
      error:null,
      image: null,
      parentId: q.parentId,
            
    };
  }


  filter(event)
  {
    const target = event.target;
//    const name = target.name;
    var value = target.value;

    this.fillList(value);

  }


fillList(filter)
{
  var rows1 = [];

//  console.log(this.state.projectId)

  helper.getFromServer(g_link+`?dw=get&projectId=${helper.getURI(this.state.projectId)}&pluginId=${helper.getURI(this.state.pluginId)}`)
  .then(res => {

    this.setState({showForm: false, rows: res.result, options: res.options});
  })
  .catch(err => {
    this.setState({error: err});
  })


}
 componentDidMount() {

  }

  showModal(item)
  {
      
//    this.setState({showForm: true, image: "/api/media/logo"+item.id+".png", id: item.id, name: item.name, email: helper.checkNull(item.email), error: ""});
    this.setState({showForm: true, id: item.id, body: item.body, error: ""});
}

  hideModal()
  {
    this.setState({showForm: false});
  }

  saveItem()
  {
//    helper.postToServer(g_link, {dw: "update", id: this.state.id, email: this.state.email, name: this.state.name}, true)
    helper.postToServer(g_link, {dw: "update", id: this.state.id, body: this.state.body,}, true)
.then(res => {
      this.fillList();
    })
    .catch(err => {
      this.setState({error: err});
    })

  }
  addItem()
  {

    if (!this.state.projectId || !this.state.pluginId)
    {
      helper.handleError("Please select a project and a plugin");
      return;

    }

    helper.postToServer(g_link, {dw: "add", projectId:this.state.projectId, pluginId: this.state.pluginId}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }

  deleteItem()
  {

    helper.postToServer(g_link, {dw: "del", id: this.state.id}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }

  
  hic(event) {

    const target = event.target;
    const name = target.name;

    let value;
//    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.type === 'checkbox')
    {
      value = this.state[name];
      if (!value)
        value = [];
        // assumes DOM won't feed us dupes
        var vint = parseInt(target.value);

      if (target.checked)
      {
        value.push(vint);
      }
      else
      {
        var index = value.indexOf(vint);
        if (index !== -1) value.splice(index, 1);
      }
    }
    else
    {
      value = target.value;
    }
    this.setState({
      [name]: value
    });
  
  }


  onFile() {

    var file = document.getElementById(undefined).files[0];
  
    var formData = new FormData();
    formData.append('file', file);
    formData.append('id', this.state.id)
    formData.append("dw", "saveFile")
    
    fetch(config.back+g_link+"_up", {
      method: 'POST', credentials: "same-origin",
      body: formData
    }).then(response => response.json())
    .then(res => {
      
      this.setState({image: "/api/media/!!upload_tag!!"+this.state.id+".png?"+new Date().getMilliseconds(),
    alert: "File was uploaded"});
    })
    .catch(error => { 
      alert(error);
    });
   //.then(response => console.log('Success:', response));
    
  }
    
  setProject(id, name)
  {
    this.setState({projectName: name, projectId: id}, () =>
    { this.fillList()})
  }

  setPlugin(id, name)
  {
    this.setState({pluginName: name, pluginId: id}, () =>
    { this.fillList()})
  }
  
  render() {
// https://www.w3schools.com/howto/howto_css_two_columns.asp
    
document.title = "Frac";
return(

  <div className="flex-container" style={{maxWidth:"1200px"}}>

<div class="column">
    <div class="card">
      <h3>Card 1</h3>
      <p>Some text</p>
      <p>Some text</p>
    </div>
  </div>

  <div class="column">
    <div class="card">
      <h3>Card 2</h3>
      <p>Some text</p>
      <p>Some text</p>
    </div>
  </div>
  
  <div class="column">
    <div class="card">
      <h3>Card 3</h3>
      <p>Some text</p>
      <p>Some text</p>
    </div>
  </div>
  
  <div class="column">
    <div class="card">
      <h3>Card 4</h3>
      <p>Some text</p>
      <p>Some text</p>
    </div>
    </div>

    <div class="column">
    <div class="card">
      <h3>Card 1</h3>
      <p>Some text</p>
      <p>Some text</p>
    </div>
  </div>

  <div class="column">
    <div class="card">
      <h3>Card 2</h3>
      <p>Some text</p>
      <p>Some text</p>
    </div>
  </div>
  
  <div class="column">
    <div class="card">
      <h3>Card 3</h3>
      <p>Some text</p>
      <p>Some text</p>
    </div>
  </div>
  
  <div class="column">
    <div class="card">
      <h3>Card 4</h3>
      <p>Some text</p>
      <p>Some text</p>
    </div>
    </div>    
</div> 

)

}
}

export default ModalList1
