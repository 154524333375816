import React, {Suspense, lazy} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import App from './App';
import * as serviceWorker from './serviceWorker';
import Login from "./Login"
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
//import { Line } from 'react-chartjs-2';
import loaderGif from './assets/loader.gif'

const config = require('./config.js');
//const Pelements = require("./list_pelements").default;
const Telements = require("./list_telements").default;
const CTypes = require("./list_ctypes").default;
const Header = require("./header").default;
//const Projects = require("./user/list_projects").default;
const Builder = require("./build1").default;
const CModeler = require("./form_cmodeler").default;
//const Users = require("./list_users").default;
const helper = require("./helper");
//const DataTable = require("./comp_misc").dataTable;
//var Barcode = require('react-barcode');

function checkName()
  {
    if (sessionStorage.getItem("name"))
      return true;
    sessionStorage.setItem("rd", document.location)
    return false;
  }
  
const PrivateRoute = ({ component: Component, ...rest }) => 
(
 <Route {...rest} render={(props) => (

	  checkName()	? <Component {...props} />
			: <Redirect to='/login' />
  )
} />
)


class FormTransitions extends React.Component
{
  constructor(props)
  {
    super(props);
    var id = helper.parseQuery()["id"];
    this.state = {
      id: id,
    }
  }

  render()
  {
    return(
      <div>
        <img src={`/api/media2/inputs/${this.state.id}.svg`} /> <img src={`/api/media2/results/${this.state.id}.svg`} />
        </div>
    )
  }
}


class Contact extends React.Component
{

  render()
  {
    return (
      <div>Please contact <a href="https://omnicode.com/contact.php">Omnicode</a> if you have any questions.</div>
    )
  }
  
}

const Test = () =>
{
  return(<div>Test!</div>)
}


const About = () => {
  return(
    <div>
      Omnimodel is a Machine Learning & Project Modeling Application.
    </div>
  )
}

/*
class Graph1 extends React.Component
{

  constructor()
  {
    super();
    var key = helper.parseQuery()["key"];

    this.state = {
      data: null,
      key: key,
    }
  }

  fixResults(data)
  {
    var data1 = data.data1;
    var data2 = data.data2;
    data2.labels.splice(0, 5);


    for (var k = 0; k < data1.datasets.length; k++)
    {
      var d1 = data1.datasets[k].data;
      data2.datasets[k].data = [];
      var d2 = data2.datasets[k].data;
    
      for (var i = 0; i < d1.length-7; i++) // not the 7 instead of 6 is because we don't trust the last day
      {
        var v = 0;
        for (var j = i+1; j < i+6; j++)
        {
          v += d1[j]-d1[j-1];
        }
        v = v/5;

        d2.push(v);
      }
    }
  }

  componentDidMount()
  {
    helper.getFromServer("/data/graph1?key="+this.state.key)
    .then(res => {

//      console.log(res.result.data1)

      var data = {data1: res.result.data1, data2: JSON.parse(JSON.stringify(res.result.data1))};
      this.fixResults(data);

      var data2 = {data1: res.result.data2, data2: JSON.parse(JSON.stringify(res.result.data2))};
      this.fixResults(data2);

      this.setState({data1: data.data1, data2: data.data2, data3: data2.data1, data4: data2.data2, options: res.options});
      
    })

  }
render()
{

  if (!this.state.options)
  {
    return (<div></div>)
  }
  return (
  <div>
    <h2>Graph for {this.state.options.name}</h2> 
    If daily counts go below 0, it means that reporting authorities have adjusted to make up for previous inaccuracies.  If data is out of date, the site is probably reimporting.  Check back later.  Thank you.
    <table className="graph-table">
      
      <tbody>
      <tr>
        <td>
          Cases/million
        <Line data={this.state.data1} />
        </td>
        <td>
          Daily Cases/million (5 day moving average)
        <Line data={this.state.data2} />
        </td>
        </tr>
        
        <tr>
        <td>
        Deaths/million
        <Line data={this.state.data3} />
        </td>
        <td>
        Daily Deaths/million (5 day moving average)
        <Line data={this.state.data4} />
        </td>
        </tr>
        </tbody>        
        </table>        
    </div>
);
}
}
*/


const Home = () => 
{
  return <div>
    Welcome to Omnimodel
  </div>
}


/*
class Home extends React.Component
{
  constructor()
  {
    super();
    var q = helper.parseQuery();
    var pk = q.pk;

    var o = helper.parseQuery().o;
//    console.log("o " + o);
    if (!o)
      o = 8;

    this.state = {data: null,
      order: o,
    pk: pk};

  }
  componentDidMount()
  {
    let q;
    if (this.state.pk)
      q = `?pk=${this.state.pk}`
    else
      q = "";
    helper.getFromServer("/data/covid"+q)
    .then(res => {
      console.log(res);
  //    var data = [[3,3,3,4], [6,6,6,7]];
      var data1 = [];
      var keys = [];
  
      res.result.map(line => {
        data1.push([line[0], line[1], line[2], Math.round(1000000*line[1]/line[3]), 
          Math.round(1000000*line[2]/line[3]), line[3], line[5], line[6], line[7]])
          keys.push(line[4]);
      })

      this.setState({data: data1, options: res.options, keys: keys})
  
    })
  }
  render()
  {
    document.title = "OmniModel";
    if (this.state.data)
    {
    return (
        <div>{config.name}<br/><br/>
        Data last updated: {this.state.options.updated}.  Note - <a href="https://www.bsg.ox.ac.uk/research/research-projects/coronavirus-government-response-tracker">Stringency</a> of 0 means data is missing, not lack of stringency.  
        If daily counts go below 0, it means that reporting authorities have adjusted to make up for previous inaccuracies.<br/>
        If data is out of date, the site is probably reimporting.  Check back later.  Thank you.        
        <br/>
        <br/>

        <DataTable keys={this.state.keys} order={this.state.order} cols={["Entity","Cases", "Deaths", "Cases/Million", "Deaths/Million", "Population", "Stringency", "New Cases/Million", "New Deaths/Million"]} data={this.state.data}/>
        </div>
    );
    
    }
  else
  {
    document.title = "OmniModel";
    return (<div></div>)
  }
}
}
*/

function Logout() {


    var link = config.back+"/logout"
      fetch(link, {
      method: 'POST', credentials: "same-origin"})
      .then(res => res.json())
        .catch(error => 
            console.error('Error:', error))
        .then(function(response) 
        { 

      })
      sessionStorage.clear();
      document.location = "/home"
    
    }

  class HandleToken extends React.Component
  {
    componentDidMount()
    {
      
      var q = helper.parseQuery(document.location.search);
      helper.postToServer("/tokens", {dw: "login", tid: q.tid})
      .then(res => { 
        console.log(res);
        if (res.result === "OK")
        {
          sessionStorage.setItem("name", res.name);
//          sessionStorage.setItem("uid", res.uid);
          document.location = res.info;
        }

      })

    }
    render()
    {
      return(
      <div>Need Token</div>
      )
        
      

    }
  }

  const NotFound = () => <div>Page Not found</div>

function route1() {
    return (
      <Router>
<div>
        <Header/>
        <div style={{margin: "20px"}}>
          <Suspense fallback={<div><img src={loaderGif} alt="loading..." /></div>}>
        <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/home" component={Home} />
        <Route path="/finance" component={require("./finance").default} />
           <Route path="/about" component={About} /> 
           <Route path="/contact" component={Contact} /> 
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
      <Route path="/build" component={Builder} /> 
      <Route path="/token" component={HandleToken} />
{/*      <Route path="/graph1" component={Graph1} /> */}
      <Route path="/fgraph" component={require("./fgraph").default} />

{/*      <Route path="/3d" component={require("./3d/test").default} /> */}

          <PrivateRoute path="/user/frac" component={require("./user/list_frac").default} /> 

          <PrivateRoute path="/user/feed" component={require("./user/list_feed").default} /> 
          <PrivateRoute path="/user/form_feed" component={require("./user/form_feed").default} /> 
          <PrivateRoute path="/user/model_trains" component={require("./user/list_model_trains").default} /> 
          <PrivateRoute path="/user/static_configs" component={require("./user/list_static_configs").default} /> 
          <PrivateRoute path="/user/form_static_configs" component={require("./user/form_static_configs").default} /> 
          <PrivateRoute path="/user/cmodeler" component={CModeler} /> 
          <PrivateRoute path="/user/lsets" component={require("./user/list_sets").default} />
          <PrivateRoute path="/user/lset_items" component={require("./user/list_lset_items").default} />
          <PrivateRoute path="/user/model" component={require("./user/list_lset_items").model} />
          <PrivateRoute path="/user/dev" component={require("./user/form_dev").default} />
          <PrivateRoute path="/user/vis" component={require("./user/list_vis").default} />
          <PrivateRoute path="/user/dnns" component={require("./user/list_dnns").default} />
          <PrivateRoute path="/user/child_source" component={require("./user/form_child_source").default} />
          <PrivateRoute path="/user/segmentations" component={require("./user/list_segmentations").default} />
          <PrivateRoute path="/user/form_segmentations" component={require("./user/form_segmentations").default} />
          <PrivateRoute path="/user/predictors" component={require("./user/list_predictors").default} />
          <PrivateRoute path="/user/form_predictors" component={require("./user/form_predictors").default} />
          <PrivateRoute path="/user/transitions" component={require("./user/list_transitions").default} />
          <PrivateRoute path="/user/trans_sets" component={require("./user/list_trans_sets").default} />
          <PrivateRoute path="/user/media" component={require("./user/list_media").default} />
          <PrivateRoute path="/user/form_transitions" component={FormTransitions}/>
          <PrivateRoute path="/user/dsets" component={require("./user/list_dsets").default} />
          <PrivateRoute path="/user/dset_items" component={require("./user/list_dset_items").default} />
          <PrivateRoute path="/user/finance/stocks" component={require("./user/list_stocks").default} />
          <PrivateRoute path="/user/finance/compare" component={require("./user/list_compare").default} />
          <PrivateRoute path="/user/finance/graph" component={require("./user/list_stocks").Graph} />
          <PrivateRoute path="/user/finance/portfolios" component={require("./user/list_portfolios").default} />
          <PrivateRoute path="/user/finance/transactions" component={require("./user/list_fin_trans").default} />
          <PrivateRoute path="/user/finance/events" component={require("./user/list_fin_events").default} />
          <PrivateRoute path="/user/frame_form" component={require("./frame_form").default} />
          <PrivateRoute path="/user/viz3" component={lazy(() => import("./user/form_viz_3"))} />
          <PrivateRoute path="/user/todo" component={lazy(() => import("./user/list_todo"))} />
          <PrivateRoute path="/user/list_csc" component={lazy(() => import("./user/list_csc"))} />
          <PrivateRoute path="/trans" component={lazy(() => import("./trans1"))} />

          <PrivateRoute path="/admin/loops" component={require("./admin/list_loops").default} />
          <PrivateRoute path="/admin/finance/strategies" component={require("./admin/list_strategies").default} />

          
          <PrivateRoute path="/user/projects" component={require("./user/list_projects").default} />
          <PrivateRoute path="/admin/plugins" component={require("./admin/list_plugins").default} />
          <PrivateRoute path="/user/project_costs" component={require("./user/list_project_costs").default} />
{/*          <PrivateRoute path="/user/project_alts" component={require("./user/list_project_alts").default} /> */}
          <PrivateRoute path="/user/form_project" component={require("./user/form_project").default} />
          <PrivateRoute path="/user/simers" component={require("./list_simers").default} />
          <PrivateRoute path="/user/telements" component={Telements} />
          <PrivateRoute path="/user/ctypes" component={CTypes} />
          <PrivateRoute path="/admin/users" component={require("./admin/list_users").default} /> 
          <PrivateRoute path="/admin/tokens" component={require("./list_tokens").default} /> 

          <Route path="/rpass1" component={require("./reset_pass1").default} />
        <Route path="/rpass2" component={require("./reset_pass2").default} />
        <Route component={NotFound} />
        </Switch>
        </Suspense>
        </div>
        </div>                
      </Router>
    );
  }

ReactDOM.render(route1(), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
