const React = require("react")
const tokenize = require("./helper.js").tokenize;
const helper = require("./helper");
const config = require("./config");

function parseElement(line)
{
  var e = new Object();
  var a = tokenize(line);
  for (var i = 1; i < a.length; i += 3)
  {
    e[a[i]] = a[i+2];
  }
  return e;
}


class PtForm
{
    constructor()
    {
        this.fields = [];
        this.names = [];
        this.parent = null;
        this.type = "";
        this.saveType = 0;
    }

    addField(name, type, values)
    {
        this.names.push(name);
        this.fields.push(<p>{name}: </p>);
//        this.fields.push(": ");

      if (type == "textarea")
      {
        this.fields.push(<p><textarea id={name} rows="3" cols="40" /></p>);

      }
      else if (type == "select" && values != null)
      {
        this.fields.push(<p><input id={name} /></p>);
        
      }
      else
      {
        this.fields.push(<p><input id={name} /></p>);
      }

    }

    initWait()
    {
       
      this.type = "wait";

      this.fields = [];
      this.names = [];
      this.addField("duration", "float");
//      this.addField("order", "int");
    }

    initSwitcher()
    {
       
      this.type = "switcher";

      this.fields = [];
      this.names = [];
      this.addField("target", "string");
      this.addField("part", "string");
      this.addField("alt", "int");
      this.addField("interval", "float");
      this.addField("duration", "duration");
    }

    initLineSet()
    {
       
      this.type = "line_set";

      this.fields = [];
      this.names = [];
      this.addField("lines", "textarea");
    }

    initTilter()
    {
       
      this.type = "tilter";

      this.fields = [];
      this.names = [];
      this.addField("target", "select");
      this.addField("requireTalking", "int");
      this.addField("name", "string");

    }

    initJumper()
    {
       
      this.type = "jumper";

      this.fields = [];
      this.names = [];
      this.addField("target", "select");
      this.addField("requireTalking", "int");
      this.addField("start", "int");
      this.addField("duration", "int");
      this.addField("name", "string");

    }

    initImport()
    {
      this.fields = [];
      this.names = [];
      this.addField("lines", "textarea");

      this.saveType = 1;

    }

    initControl()
    {
      this.type = "control";

      this.fields = [];
      this.names = [];
      this.addField("target", "string");
      this.addField("function", "select", ["on", "off"]);

    }

    initWalker()
    {
      this.type = "walker";

      this.fields = [];
      this.names = [];
      this.addField("target", "string");
      this.addField("start", "string");
      this.addField("stop", "string");

    }

    initBgImage()
    {
      this.type = "bgimage";

      this.fields = [];
      this.names = [];
      this.addField("name", "string");
//      this.addField("file1", "string"); 
      this.addField("start", "string");
      this.addField("stop", "string");
      this.addField("interval", "string");

    }

    init(type)
    {
      if (type == "wait")
        this.initWait();
      else if (type == "tilter")
        this.initTilter();
      else if (type == "switcher")
        this.initSwitcher();
      else if (type == "control")
        this.initControl();
      else if (type == "walker")
        this.initWalker();
      else if (type == "bgimage")
        this.initBgImage();
      else if (type == "jumper")
        this.initJumper();
      else if (type == "line_set")
        this.initLineSet();
    }

    setData(line)
    {
        var a = parseElement(line);
//console.log(a);
        var k = Object.keys(a);
        for (var i = 0; i < k.length; i++)
      {
        if (document.getElementById(k[i]) == null)
          continue;
//        console.log(k[i]);
        document.getElementById(k[i]).value = a[k[i]];
      }


    }

    save1()
    {
      var lines = document.getElementById("lines").value;
      if (!lines || lines.trim() == "")
      {
        helper.showError("Please enters some lines");
        return;
      }
      helper.postToServer("/user/scenes", {_id: this._id, dw: "parseLines", lines: lines})
      .then(res =>{
        if (res.code !== 200)
          alert(res.result)
        this.parent.hideModal();
        this.parent.fetchRows();
      })
      .catch(err => {
        alert(err);
      })
    }
    save()
    {
      if (this.saveType == 1)
      {
        this.save1();
        return;
      }
      var line = this.type + ": ";

//        let order;

        for (var i = 0; i < this.names.length; i++)
        {
/*          
          if (this.names[i] == "order")
          {
            order = document.getElementById(this.names[i]).value;
            continue;
          } */
//            line += this.names[i] + '="' + this.parent.state[this.names[i]] + '" ';
            line += this.names[i] + '="' + document.getElementById(this.names[i]).value + '" ';
}


      helper.postToServer("/user/selements", {dw:'update', _id: this.parent.state._id, line: line})
      .then(res =>{
        if (res.code !== 200)
          alert(res.result)
        this.parent.hideModal();
        this.parent.fetchRows();
      })
      .catch(err => {
        alert(err);
      })
    }
    show()
    {
      this.parent.setState({ showGen: true, form: this, fields: this.fields});
    }

}

function getSelectType1()
{
    return(
    <select id="stype">  
    <option value="room">Room</option>

</select>);

}

export const getSelectType = getSelectType1;

export default PtForm