import React from "react";
import { Line } from 'react-chartjs-2';
import StForm, {clipTypes} from "../StForm";


const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const TextPrompt = require("../comp_misc").textPrompt;
const Select1 = require("../comp_misc").Select1;
const misc = require("../comp_misc");

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/user/data_sets";

function ListItemPic(props) {

  // Correct! There is no need to specify the key here:
  return <tr>
    <td><a href={`/user/frame_form?id=${props.item.leftId}`}><img src={`/api/tn/media${props.item.leftId}_tn.png`}/></a></td>
    <td><a href={`/user/frame_form?id=${props.item.rightId}`}><img src={`/api/tn/media${props.item.rightId}_tn.png`}/></a></td>
    <td>{props.item.className}</td>
  <td align="center"><button type="button" class="btn btn-outline-primary" onClick={() =>
    {
  //  props.parent.showModal(props.item) 

  var form = new StForm();
  form.parent = props.parent;
//  form.setData(line).then(() => {
//    form.initWait();
    form.init("pic", props.item);
      form.link = g_link;
      form.id = props.item.id;
//      form.dw = "updateJobj";
      props.parent.form = form;
    props.parent.setState({ showGen: true, id: props.item.id, form: form, fields: form.fields, currentRid: props.item.id, type: "pic"}, () => {
//      line += ' order="'+order + '"';
//      console.log(line);
      form.setData(props.item);
    });

  
  }


  }><i class="fa fa-edit mr-2" aria-hidden="true"></i>Edit</button></td>  

</tr>

}


function ListItemChart(props) {
  var data = {
		labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
		datasets: [
		  {
        label: "Price",
        fill: false,        
        data: props.item.inputs
      }
    ]}

  // Correct! There is no need to specify the key here:
  return <tr>
    <td><td>{props.item.number}</td><Line  data={data} /></td><td>{props.item.className}</td>
  <td align="center"><button type="button" class="btn btn-outline-primary" onClick={() =>
    {
    props.parent.showModal(props.item) }
  }><i class="fa fa-edit mr-2" aria-hidden="true"></i>Edit</button></td>  

</tr>

}

class ModalList1 extends React.Component {
  
  constructor(props)
  {
    super(props);
    var q = helper.parseQuery();
    this.state = {
      show: false,
      rows: null,
      name:null,
      error:null,
      image: null,
    pid: q.id,
    o: q.o,            
    };
    this.dsid = q.id;
    this.form = null;
  }


  filter(event)
  {
    const target = event.target;
//    const name = target.name;
    var value = target.value;

    this.fillList(value);

  }



fillList(filter)
{
  var rows1 = [];

  helper.getFromServer(`${g_link}?dw=getItems&pid=${this.state.pid}&o=${this.state.o}`)
  .then(res => {
    console.log(res)
    this.setState({showForm: false, rows: res.result, options: res.options});
  })



}
 componentDidMount() {
  this.fillList()  
  }

  showModal(item)
  {

      
//    this.setState({showForm: true, image: "/api/media/logo"+item.id+".png", id: item.id, name: item.name, email: helper.checkNull(item.email), error: ""});
    this.setState({showForm: true, id: item.id, classId: item.classId, error: ""});
}

  hideModal()
  {
    this.setState({showForm: false});
  }

  saveItem()
  {
//    helper.postToServer(g_link, {dw: "update", id: this.state.id, email: this.state.email, name: this.state.name}, true)
    helper.postToServer(g_link, {dw: "updateItem", id: this.state.id, classId: this.state.classId,}, true)
.then(res => {

      this.fillList();
    })
    .catch(err => {
      this.setState({error: err});
    })

  }
  addItem()
  {

    helper.postToServer(g_link, {dw: "addJobj", pid:this.state.pid}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }

  deleteItem()
  {

    helper.postToServer(g_link, {dw: "del", id: this.state.id}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }

  
  hic(event) {

    console.log("change!")
    const target = event.target;
    const name = target.name;

    let value;
//    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.type === 'checkbox')
    {
      value = this.state[name];
      if (!value)
        value = [];
        // assumes DOM won't feed us dupes
        var vint = parseInt(target.value);

      if (target.checked)
      {
        value.push(vint);
      }
      else
      {
        var index = value.indexOf(vint);
        if (index !== -1) value.splice(index, 1);
      }
    }
    else
    {
      value = target.value;
    }
    this.setState({
      [name]: value
    });
  
  }

  generate()
  {
    helper.postToServer(g_link, {dw: "generate", pid: this.state.pid})
    .then(res => {
      this.fillList();
    })
    .catch(err => {
      helper.handleError(err);
    })
  }

  guess()
  {
    helper.postToServer(g_link, {dw: "guess", pid: this.state.pid})
    .then(res => {
      this.fillList();
    })
    .catch(err => {
      helper.handleError(err);
    })
  }
  onFile() {

    var file = document.getElementById(undefined).files[0];
  
    var formData = new FormData();
    formData.append('file', file);
    formData.append('id', this.state.id)
    formData.append("dw", "saveFile")
    
    fetch(config.back+g_link+"_up", {
      method: 'POST', credentials: "same-origin",
      body: formData
    }).then(response => response.json())
    .then(res => {
      
      this.setState({image: "/api/media/!!upload_tag!!"+this.state.id+".png?"+new Date().getMilliseconds(),
    alert: "File was uploaded"});
    })
    .catch(error => { 
      alert(error);
    });
   //.then(response => console.log('Success:', response));
    
  }
    

  render() {
document.title = "Dataset Lists";
let listItems;
//

let litem, col1, col2;

if (this.state.rows)
{
  
  if (this.state.options.type == "image")
  {
    col1 = "Left";
    col2 = "Right";
    listItems = this.state.rows.map((item, index) =>
    <ListItemPic key={index} item={item} parent={this} />
  );
  }
  else
  {
    col1 = "Input";
    col2 = "Output";

    listItems = this.state.rows.map((item, index) =>
    <ListItemChart key={index} item={item} parent={this} />
  );

    
  }


}
else
{
  return (<div></div>)
}

return(

<div style={{margin: "30px"}}>
  <br/>
  <a href="/user/dsets">Datasets</a><br/>
  {this.state.options.name}<br/>
<button onClick={() => {
  helper.postToServer(g_link, {dw: "addJobj", sid: this.dsid})
  .then(() => {
    this.fillList();
  })
}}>Add</button><misc.Space/>
<button onClick={this.generate.bind(this)}>Generate</button> 
<misc.Space/>
<button onClick={this.guess.bind(this)}>Guess</button>
<misc.Space/>
<a href={`/api/user/model_zip?dsid=${this.dsid}`} download>Download train script</a>
<misc.Pager link={`/user/dset_items?pid=${this.state.pid}&`} pager= {this.state.options.pager}/>

<table className="table">
<tbody>
    <td>{col1}</td>
    <td>{col2}</td>
	
    
  {listItems}
  
	</tbody>
  </table>
  <br/>
  <misc.Pager link={`/user/dset_items?pid=${this.state.pid}&`} pager= {this.state.options.pager}/>

  <Modal show={this.state.showForm} handleClose={this.hideModal.bind(this)}>
          <br/>
          <div><TextPrompt name="name" title="name" onChange={this.hic.bind(this)} value={this.state.name}/></div>

<div>Class: <misc.Space />

<Select1 name="classId" hic={this.hic.bind(this)} options={this.state.options.classList} value={this.state.classId}/>

</div>

        <button type="button" className="btn btn-primary" onClick={this.saveItem.bind(this)}>Save</button>&nbsp;
        <button type="button" className="btn btn-primary" onClick={this.deleteItem.bind(this)}>Delete</button>&nbsp;

</Modal>

<Modal show={this.state.showGen} handleClose={() => {
  this.setState({showGen:false})}} id="gen_form">
          <br/>
          {this.state.fields}
          <p>
            <button type="button" className="btn btn-primary" onClick={(e) => {
              this.form.save();
              this.setState({showGen:false})
            }}>Save</button>&nbsp;
          <button type="button" className="btn btn-primary" onClick={this.deleteItem.bind(this)}>Delete</button>&nbsp;
          </p>
            </Modal>


</div>
)


}

}

export default ModalList1
