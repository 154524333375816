import React from 'react';
import { Line } from 'react-chartjs-2';
const Grapher = require("./grapher").default;

const config = require('./config.js');
const helper = require("./helper");

const g_link = "/data/fing"

class Finance extends React.Component
{
  constructor()
  {
    super();
    var key = helper.parseQuery()["key"];

    this.state = {
      data: null,
      key: key,
    }

  }

  fixResults(data)
  {
    var data1 = data.data1;
    var data2 = data.data2;
    data2.labels.splice(0, 5);


    for (var k = 0; k < data1.datasets.length; k++)
    {
      var d1 = data1.datasets[k].data;
      data2.datasets[k].data = [];
      var d2 = data2.datasets[k].data;
    
      for (var i = 0; i < d1.length-7; i++) // not the 7 instead of 6 is because we don't trust the last day
      {
        var v = 0;
        for (var j = i+1; j < i+6; j++)
        {
          v += d1[j]-d1[j-1];
        }
        v = v/5;

        d2.push(v);
      }
    }
  }

  componentDidMount()
  {
    helper.getFromServer(`${g_link}?key=${this.state.key}`)
    .then(res => {
//      console.log(res);
      
      this.setState({datasets: res.result.datasets, labels: res.result.labels, options: res.options});

    })
  }
  render()
  {

    if (this.state.datasets)
    {
      var keys = this.state.key.split(",");

    return (
        <div>{config.name}<br/><br/>
        Data last updated: {this.state.options.updated}.  
        <br/>
        <br/>
        <table className="graph-table">
        <tbody>
      <tr>
        <td>
        <Grapher datasets={this.state.datasets} labels={this.state.labels} keys={keys} />
        </td>
        </tr>
        </tbody>
        </table>
        </div>
    );
    }
  else
  {
    return (<div>Waiting</div>)
  }
}
}

export default Finance;


