import React from 'react';

import * as THREE from 'three';

var camera, scene, renderer;
var geometry, material, mesh;

var scene = new THREE.Scene();

//var g_firstRoom = null;
//var g_lastRoom = null;
var g_canvas = null;
//var g_rooms = Array();
var g_height = 425

var dropX = 0;

class Module
{
  width = 10;
  height = 20;
  points = new PointList();
  create()
  {

  }
}

class Point
{
  x = 0;
  y = 0;

}

class PointList
{
  points = [];

}

class Ground
{
  width = 10;
  length = 10;
  
  createMesh()
  {
    var geometry = new THREE.PlaneGeometry( 5, 5, 5,5 );
    var material = new THREE.MeshBasicMaterial( {color: 0xffff00, side: THREE.DoubleSide} );
    var plane = new THREE.Mesh( geometry, material );
    return plane;

  }
}

/*
class Wall
{
  scale = .01;
  x = 0;
  y = 0;
  width = 20;
  height = 8;
  dir = 0;
  normal = Math.PI/2;
  createMesh()
  {
    var geometry = new THREE.BufferGeometry();

    var w1 = this.scale*this.width/2;
    var h1 = this.scale*this.height/2;


    geometry.vertices.push(
      new THREE.Vector3( -1*w1,  h1, 0 ),
      new THREE.Vector3( -1*w1, -1*h1, 0 ),
      new THREE.Vector3(  w1, -1*h1, 0 ),
      new THREE.Vector3(  w1, h1, 0 )
    );
    
    geometry.faces.push( new THREE.Face3( 0, 1, 2 ) );
    geometry.faces.push( new THREE.Face3( 0, 2, 3 ) );
    
    geometry.computeBoundingSphere();
    
    geometry.computeFaceNormals(); 


var material = new THREE.MeshLambertMaterial({color: 0x7777ff});

    var plane = new THREE.Mesh( geometry, material );
    return plane;
    
  }
}
*/

/*
class Building
{
  modules = [];

  create()
  {
    for (var i = 0; i < this.modules.length; i++)
    {
      this.modules[i].create();
    }
  }
  addWall(width, height, dir)
  {
    var wall = new Wall();
    wall.width = width;
    wall.height = height;
    wall.dir = dir;
    wall.normal = dir + Math.PI/2;

    this.modules.push(wall);
  }

  getThreeGroup()
  {
    var group = new THREE.Group();
    var wall = new Wall();
    
    group.add(wall.createMesh());
    wall = new Wall();
    var mesh = wall.createMesh();
    mesh.rotation.y = Math.PI/2;

    group.add(mesh);

    return group; 
//    return wall.createMesh();

  }
  
}
*/

class ScoreMaker
{


}


function animate() 
{
 
  requestAnimationFrame( animate );

/*  mesh.rotation.x += 0.01;
  mesh.rotation.y += 0.02;


  mesh.position.x = .7;
*/

  renderer.render( scene, camera );

}


class  GeonBuilder extends React.Component 
{
constructor() {
  super()

  this.state = {
  };

  
}  
  


  componentDidUpdate() {
/*      
    const ctx = g_canvas.getContext("2d")
    ctx.clearRect(0, 0, g_canvas.width, g_canvas.height);
      
    this.manager.draw(ctx)
*/

  }

  componentDidMount()
  {
    camera = new THREE.PerspectiveCamera( 70, window.innerWidth / window.innerHeight, 0.01, 10 );
    camera.position.z = 3;
  
    camera.position.y = 1;

    scene = new THREE.Scene();
 
        // add subtle ambient lighting
        var ambientLight = new THREE.AmbientLight(0xffffff);
        scene.add(ambientLight);
/*
        // add spotlight for the shadows
        var spotLight = new THREE.SpotLight(0xffffff);
        spotLight.position.set(.5, 0, 0);
//        spotLight.castShadow = true;
        scene.add(spotLight);
*/

/*
    scene.add( new THREE.AmbientLight( 0x222222, .1 ) );
//    var light = new THREE.PointLight( 0xffffff, 1, 100000 );
//    var light = new THREE.PointLight( 0xffffff);

var spotLight = new THREE.SpotLight(0xffffff);
spotLight.position.set(-30, 60, 60);
spotLight.castShadow = true;
scene.add(spotLight);
*/

//    new THREE.PointLight(0xFFFFFF, 1, 100000);

//    camera.add( spotLight );
       
/* 
    geometry = new THREE.BoxGeometry( 0.2, 0.2, 0.2 );
    material = new THREE.MeshNormalMaterial();
  
    mesh = new THREE.Mesh( geometry, material );
    
    //scene.add( mesh );
  */
/*
https://threejs.org/examples/#webgl_geometry_convex
 scene.add( new THREE.AmbientLight( 0x222222 ) );
 // light
 var light = new THREE.PointLight( 0xffffff, 1 );
 camera.add( light );
*/


    var directionalLight = new THREE.DirectionalLight( 0xffffff, 0.7 );
    directionalLight.position.y = 0;
    directionalLight.position.z = 1;
    scene.add( directionalLight ); 

    var ground = new Ground;
    var m = ground.createMesh();
    m.rotation.x = -Math.PI/2;
  
    scene.add(m);

    renderer = new THREE.WebGLRenderer( { antialias: true, canvas: document.getElementById("artifactCanvas") } );
    renderer.setSize( window.innerWidth, window.innerHeight );
    //renderer.setSize(500,500);
    document.body.appendChild( renderer.domElement );  
    animate();

  }
  
  addClick()
  {

/*
var b = new Building();

var plane = b.getThreeGroup();

plane.position.x = dropX;
dropX -= .3;

scene.add( plane );
*/
  }

  render() 
  {
    return (
      <div class="container">
      <br/>
      
 <div class="row">
 <div class="column" style={{margin:"20px"}}>

<input type="button" onClick={this.addClick.bind(this)} value="Add Module"/><br/><br/>
<input type="button" value="Connect Modules"/><br/>
<br/>
 <div class="btn-group-vertical" role="group" aria-label="Basic example">

 </div>

</div>
 <div class="column">
      <canvas ref="canvas" id="artifactCanvas" width={640} height={425} onClick={this.canvasClick} />
  </div>
</div>       
      </div>)
  }
}

export default GeonBuilder;

