import React from "react";
const config = require("./config");
const helper = require("./helper");
const Modal = require("./comp_misc").modal;
const TextPrompt = require("./comp_misc").textPrompt;

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/user/simers";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    <td>{props.item.name}</td>
  <td align="center"><button type="button" class="btn btn-outline-primary" onClick={() =>
    {
    props.parent.showModal(props.item) }
  }><i class="fa fa-edit mr-2" aria-hidden="true"></i>Edit</button></td>  

</tr>

}

class ModalList1 extends React.Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      show: false,
      rows: null,
      name:null,
      email:"",
      error:null,
      image: null,
    };
  }




fillList()
{
  var rows1 = [];

  helper.getFromServer(g_link+"?dw=get")
  .then(res => {

    var options = {};

    

    this.setState({showForm: false, rows: res.result, options: options});
  })



}
 componentDidMount() {
  this.fillList()  
  }

  showModal(item)
  {
      
//    this.setState({showForm: true, image: "/api/media/logo"+item.id+".png", id: item.id, name: item.name, email: helper.checkNull(item.email), error: ""});
    this.setState({showForm: true, id: item.id, name: item.name, error: ""});
}

  hideModal()
  {
    this.setState({showForm: false});
  }

  saveItem()
  {
//    helper.postToServer(g_link, {dw: "update", id: this.state.id, email: this.state.email, name: this.state.name}, true)
    helper.postToServer(g_link, {dw: "update", id: this.state.id, name: this.state.name,}, true)
.then(res => {
      this.fillList();
    })
    .catch(err => {
      this.setState({error: err});
    })

  }
  addItem()
  {

    helper.postToServer(g_link, {dw: "add"}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }

  deleteItem()
  {

    helper.postToServer(g_link, {dw: "del", id: this.state.id}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }

  
  handleInputChange(event) {

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
  
    //console.log(name)
    this.setState({
      [name]: value
    });
  
  }

  onFile() {

    var file = document.getElementById("logoUpload").files[0];
  
    var formData = new FormData();
    formData.append('file', file);
    formData.append('id', this.state.id)
    formData.append("dw", "saveFile")
    
    fetch(config.back+'/admin/upload', {
      method: 'POST', credentials: "same-origin",
      body: formData
    }).then(response => response.json())
    .then(res => {
      this.setState({image: "/api/media/logo"+this.state.id+".png?"+new Date().getMilliseconds()});
    })
    .catch(error => { 
      alert(error);
    });
   //.then(response => console.log('Success:', response));
  
  
  
  }
    

  render() {
document.title = "RPro Company List";
let listItems;
if (this.state.rows)
{


  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

}
return(

<div>
  
<button onClick={this.addItem.bind(this)}>Add Item</button><br/>

<table className="table">
    <td>Name</td>
	<tbody>
    
  {listItems}
  
	</tbody>
  </table>

  <Modal show={this.state.showForm} handleClose={this.hideModal.bind(this)}>
          <br/>
          <div><TextPrompt name="name" title="name" onChange={this.handleInputChange.bind(this)} value={this.state.name}/></div>
        <button type="button" className="btn btn-primary" onClick={this.saveItem.bind(this)}>Save</button>&nbsp;
        <button type="button" className="btn btn-primary" onClick={this.deleteItem.bind(this)}>Delete</button>&nbsp;

</Modal>

</div>
)

}
}

export default ModalList1
