import React from 'react';
import { Line } from 'react-chartjs-2';

const config = require('./config.js');
const helper = require("./helper");
const DataTable = require("./comp_misc").dataTable;

const g_link = "/data/fing";

var g_colors = [
	'rgb(240,163,255)', 'rgb(0,117,220)',  'rgb(153,63,0)', 'rgb(76,0,92)',  'rgb(25,25,25)',  'rgb(0,92,49)',  
	'rgb(43,206,72)', 'rgb(255,204,153)',  'rgb(128,128,128)', 'rgb(148,255,181)', 'rgb(143,124,0)',  'rgb(157,204,0)', 'rgb(194,0,136)',  
	'rgb(0,51,128)', 'rgb(255,164,5)',  'rgb(255,168,187)',  'rgb(66,102,0)',  'rgb(255,0,16)',  'rgb(94,241,242)',  'rgb(0,153,143)'	
];


class Grapher extends React.Component
{
  render()
  {

    if (this.props.datasets)
    {
      var data = {labels: this.props.labels};
      var datasets = [];
      for (var i = 0; i < this.props.datasets.length; i++)
      {
        var ds = {
          			label: this.props.keys[i],
                fill: false,
                lineTension: 0.1,
          			backgroundColor: g_colors[i],
                borderColor: g_colors[i], 
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
          			pointBorderColor: g_colors[i],
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
          			pointHoverBackgroundColor: g_colors[i],
                pointHoverBorderColor: g_colors[i], 
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: this.props.datasets[i],
                };
          datasets.push(ds);
      }
      
      console.log("datasets");
      console.log(datasets)
      data.datasets = datasets;

    return (
        <div>
        <table>
        <tbody>
      <tr>
        <td>
          Value:
        <Line data={data} />
        </td>
        </tr>
        </tbody>
        </table>
        </div>
    );
    }
  else
  {
    return (<div>Waiting for datasets</div>)
  }
}
}

export default Grapher;


