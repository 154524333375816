//import { ReactMic } from 'react-mic';
//import './record.css';
import "./App.css";

const React = require("react")
const config = require('./config.js');
const helper = require('./helper');
const TextPrompt = require("./comp_misc").textPrompt
const TextAreaPrompt = require("./comp_misc").textAreaPrompt;

class CModel
{
  constructor()
  {
    this.projectType = "new";
    this.buildingType = "ha!";
    
  }
}

class Component1 extends React.Component 
{

render()
{
  return (
    <div>
      Project Type<br/>
      <br/>
New<br/>
Addition<br/>
Renovation<br/>
<br/>
</div>
  );
}  
}


class Component2 extends React.Component 
{

render()
{
  return (
    <div>
      Building Type<br/>
      <br/>
Detached House<br/>
Garage<br/>
Garage with additional unit<br/>
Detached additional unit<br/>
Attached additional unit<br/>
<br/>
</div>
  );
}  
}


class CModeler extends React.Component {
  constructor(props) {
    super(props);
    console.log("construct!");

    var pid = helper.parseQuery(document.location.search).pid
    var show = [];
    show[0] = true;
    show[1] = false;

  this.state = {
    rows: null,
    name: "",
    description: "",
    _id: null,
    show: show,
  };
  }


componentDidMount() 
{
}


handleInputChange(event) {

  const target = event.target;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  const name = target.name;

  console.log(name)
  this.setState({
    [name]: value
  });

}
handleNext()
{
//  alert("next!");
  var which = -1;
  for (var i = 0; i < this.state.show.length; i++)
  {
    if (this.state.show[i] == true)
    {
      which = i;
      this.state.show[i] = false;
    }
  }
  which++;
  if (which >= this.state.show.length)
    which = 0;

  this.state.show[which] = true;
  this.setState({show: this.state.show});

}

  render() {
    document.title = "Construction Modeler"

return(

<div className="container">
<br/>
<div className={this.state.show[0] ? "display-block" : "display-none"}>
<Component1/></div>
<div className={this.state.show[1] ? "display-block" : "display-none"}>
<Component2/></div>
<input type="button" onClick={this.handleNext.bind(this)} value="Next"/>
</div>

)

  }
}

export default CModeler
