import React from "react";
//import { render } from "react-dom";

const config = require('./config.js');
const helper = require("./helper");
const Modal = require("./comp_misc").modal;
const moment = require("moment");

var g_data = {};

class ListLine extends React.Component{
  constructor(props)
  {
    super(props);
  }

  render()
  {
    return (
      <li><a href="#" onClick={(e) => 
        {this.props.parent.editToken(e, this.props.id)}
      }>{this.props.email} {this.props.description}</a> {moment(this.props.date).format("MMM Do YYYY")}
      </li>
    );

    
  }
}

class ListTokens extends React.Component {
  constructor()
  {
    super();
this.state = {
    rows: null,
  };
  this.editToken = this.editToken.bind(this);

  }

editToken(e, id)
{
//  console.log(g_data[id]);

  this.setState({show:true, tokenId: id, description: g_data[id].description, email: g_data[id].email, info: g_data[id].info});
}

saveToken()
{
//  console.log(this.state.info);

helper.postToServer("/admin/tokens", {dw: "update", id: this.state.tokenId, email: this.state.email, description: this.state.description, info: this.state.info})
.then(res => {
  this.setState({show: false});
  this.fillList();
})
.catch(err => {
  alert(err);
})

}

deleteToken()
{
  var r = window.confirm("Are you sure you want to delete this token?  Can't be undone!");
  if (r == true)
  {
    helper.postToServer("/admin/tokens", {dw: "del", id: this.state.tokenId})
    .then(res => {
      this.setState({show: false});
      this.fillList();
    })
    .catch(err => {
      alert(err);
    })
  }
}
fillList()
{
  helper.getFromServer("/admin/tokens?dw=list")
  .then(res => {
//    console.log(res)
    var j1 = res.result;
    
    var rows1 = [];
    g_data = {};
    for (var i = 0; i < j1.length; i++)
    {
      var id = j1[i]._id;
      g_data[id] = {email:j1[i].email, description:j1[i].description, info:j1[i].info};
      rows1.push(<ListLine email={j1[i].email} info={j1[i].info} description={j1[i].description} date={j1[i].date} id={id} parent={this} />);
    }

    this.setState({rows: rows1})

  })
  .catch(err => {
    alert(err)
  })


}
 componentDidMount() {
  this.fillList()  
  }

  addToken()
  {
    helper.postToServer("/admin/tokens", {dw: "add"})
    .then(res => {
//      console.log(res)
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }
  handleInputChange(event) {

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
  
    //console.log(name)
    this.setState({
      [name]: value
    });
  
  }
  
  render() {
document.title = "Token List"
    if (this.state.rows === null) {
return(
  
  <div>Token List Working on it</div>);
    } else {

return(
<div class="container">
<br/>
Token List
<ul>
  {this.state.rows}
  </ul>  
 <button type="button" class="btn btn-primary" onClick={this.addToken.bind(this)}>Add</button>
 <Modal show={this.state.show} handleClose={() => {
   this.setState({show: false});
 }}>
          <br/>
          Email: <input type="text" name="email" value={this.state.email} onChange={this.handleInputChange.bind(this)} /><br/>
          Description: <input type="text" name="description" value={this.state.description} onChange={this.handleInputChange.bind(this)}/><br/>
          Info: <input type="text" name="info" value={this.state.info} onChange={this.handleInputChange.bind(this)}/><br/>
          {"https://www.cyberhoff.com/token?tid="+this.state.tokenId}<br/>
          <input type="button" value="Delete" onClick={this.deleteToken.bind(this)}/> <input type="button" value="Save" onClick={this.saveToken.bind(this)}/>
  </Modal>


</div>

)
}
  }
}

// onClick={() => { callUrl('logout') }}

export default ListTokens


