import React from 'react';
import { Line } from 'react-chartjs-2';

const config = require('./config.js');
const helper = require("./helper");
const DataTable = require("./comp_misc").dataTable;


class Finance extends React.Component
{
  constructor()
  {
    super();
    var q = helper.parseQuery();
    var pk = q.pk;

    var o = helper.parseQuery().o;
//    console.log("o " + o);
    if (!o)
      o = 8;

    this.state = {data: null,
      order: o,
    pk: pk};

  }
  componentDidMount()
  {
    let q;
    if (this.state.pk)
      q = `?pk=${this.state.pk}`
    else
      q = "";
    helper.getFromServer("/data/fin"+q)
    .then(res => {
      console.log(res);
  //    var data = [[3,3,3,4], [6,6,6,7]];
      var data1 = [];
      var keys = [];
  
      res.result.map(line => {
  
        data1.push([line[0], line[1], line[2]])
          keys.push(line[0]);
      })

      this.setState({data: data1, options: res.options, keys: keys})
  
    })
  }
  render()
  {
    document.title = "Omnimodel Finance"
    if (this.state.data)
    {
    return (
        <div>{config.name}<br/><br/>
        Data last updated: {this.state.options.updated}.  
        <br/>
        <br/>

        <DataTable keys={this.state.keys} graphLink="fgraph" order={this.state.order} cols={["Entity","Name", "Price" ]} data={this.state.data}/>
        </div>
    );
    }
  else
  {
    document.title = "Omnimodel Finance"
    return (<div></div>)
  }
}
}

export default Finance;


