import React from "react";
import loader from '../assets/loader.gif'
import { Line } from 'react-chartjs-2';

const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const TextPrompt = require("../comp_misc").textPrompt;
const TextAreaPrompt = require("../comp_misc").textAreaPrompt;
const misc = require("../comp_misc");
const { DateTime } = require("luxon");

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/user/stocks";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    <td>{props.item.code}</td>
    <td>{props.item.strategy}</td>
    <td>{props.item.initialPrice}</td>
    <td>{props.item.latestPrice}</td>
    <td><a href={`/user/finance/transactions?code=${props.item.code}&strat=${props.item.strategyId}`}>Transactions</a></td>
    <td>{helper.formatMoney(props.item.profit)}</td>
<td>{props.item.initialInvestment}</td>
<td>{helper.formatMoney(props.item.currentStockValue)}</td>
<td>{props.item.currentCash}</td>

<td><a href={`graph?id=${props.item.id}`}>Graph</a></td>

  <td align="center"><button type="button" className="btn btn-outline-primary" onClick={() =>
    {
    props.parent.showModal(props.item) }
  }><i className="fa fa-edit mr-2" aria-hidden="true"></i>Edit</button></td>  

</tr>

}

class ModalList1 extends React.Component {
  
    q = helper.parseQuery();
    date1 = this.q.date1 ? this.q.date1 : DateTime.now().minus({ years: 1 }).toISO();;
    
    code = this.q.code;
    state = {
      show: false,
      rows: null,
      name:"",
      error:"",
      image: null,
      pid: this.q.pid,
      code: "",
            
    };


  filter(event)
  {
    const target = event.target;
//    const name = target.name;
    var value = target.value;

    this.fillList(value);

  }



fillList(filter)
{
  var rows1 = [];

  helper.getFromServer(g_link+"?dw=get&pid="+this.state.pid+"&date1="+this.date1)
  .then(res => {

    this.setState({showForm: false, rows: res.result, options: res.options});
  })



}
 componentDidMount() {
  this.fillList()  
  }

  showModal(item)
  {
      
//    this.setState({showForm: true, image: "/api/media/logo"+item.id+".png", id: item.id, name: item.name, email: helper.checkNull(item.email), error: ""});
    this.setState({showForm: true, id: item.id, name: item.name,code: item.code, strategyId: item.strategyId ? item.strategyId : 1, error: ""});
}

  hideModal()
  {
    this.setState({showForm: false});
  }

  saveItem()
  {
    
    console.log(this.state);

    helper.postToServer(g_link, {dw: "update", id: this.state.id, pid: this.state.pid, name: this.state.name,code: this.state.code, strategyId: this.state.strategyId, 
      }, true)
.then(res => {
      this.fillList();
    })
    .catch(err => {
      this.setState({error: err});
    })

  }
  addItem()
  {

    helper.postToServer(g_link, {dw: "add", pid:this.state.pid}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }

  deleteItem()
  {

    helper.postToServer(g_link, {dw: "del", id: this.state.id}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }

  
  hic(event) {

    const target = event.target;
    const name = target.name;

    let value;
//    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.type === 'checkbox')
    {
      value = this.state[name];
      if (!value)
        value = [];
        // assumes DOM won't feed us dupes
        var vint = parseInt(target.value);

      if (target.checked)
      {
        value.push(vint);
      }
      else
      {
        var index = value.indexOf(vint);
        if (index !== -1) value.splice(index, 1);
      }
    }
    else
    {
      value = target.value;
    }
    this.setState({
      [name]: value
    });
  
  }

  onFile() {

    var file = document.getElementById(undefined).files[0];
  
    var formData = new FormData();
    formData.append('file', file);
    formData.append('id', this.state.id)
    formData.append("dw", "saveFile")
    
    fetch(config.back+g_link+"_up", {
      method: 'POST', credentials: "same-origin",
      body: formData
    }).then(response => response.json())
    .then(res => {
      
      this.setState({image: "/api/media/!!upload_tag!!"+this.state.id+".png?"+new Date().getMilliseconds(),
    alert: "File was uploaded"});
    })
    .catch(error => { 
      alert(error);
    });
   //.then(response => console.log('Success:', response));
    
  }
    

  render() {
document.title = "Stocks";
let listItems;
//

if (this.state.rows)
{

  

  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

}
else
{
  return (<div><img src={loader} alt="loading..." /></div>)
}


return(

<div className="container">
  Start Date: {this.state.options.startDate} <br/>
  <br/>
<button onClick={this.addItem.bind(this)}>Add Item</button><misc.Space/>
<button onClick={async () => {


  var r = await helper.postToServer("/user/fin_trans", {dw: "genPort", date1: this.date1, pid: this.pid});

  console.log(r);

}}>Regen</button><br/>

<table className="table">
<tbody>

  <tr>
    <td>Code</td>
    <td>Strategy</td>
    <td>Initial price</td>
    <td>Latest Price</td>
    <td>Transactions</td>
    <td>Return</td>
    <td>Graph</td>
    </tr>
    
  {listItems}
  
	</tbody>
  </table>


  <Modal show={this.state.showForm} handleClose={this.hideModal.bind(this)}>
          <br/>
          <div><TextPrompt name="name" title="Name" onChange={this.hic.bind(this)} value={this.state.name}/></div> 
<div><TextPrompt name="code" title="Code" onChange={this.hic.bind(this)} value={this.state.code}/></div>
  <div>Strategy: <misc.Select1 name="strategyId" value={this.state.strategyId} hic={this.hic.bind(this)} options={this.state.options.strategyList}/></div> 
        <button type="button" className="btn btn-primary" onClick={this.saveItem.bind(this)}>Save</button>&nbsp;
        <button type="button" className="btn btn-primary" onClick={this.deleteItem.bind(this)}>Delete</button>&nbsp;

</Modal>


</div>
)

}
}

export default ModalList1

class Graph1 extends React.Component
{

  q = helper.parseQuery();
  date1 = this.q.date1;
  code = this.q.code;
  pid = this.q.pid;
  state = {
    pid: this.q.pid,
          
  };
  getDatasets(data)
  {
    return {
      labels: data.dates,
      datasets: [
        {
  			label: "test1",
        fill: false,
        lineTension: 0.1,
  /*			backgroundColor: 'rgba(75,192,192,1)',
        borderColor: 'rgba(75,192,192,1)', */
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
  //			pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
  /*			pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)', */
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: data.prices,
        },
      ]
      };
  
  }
/*
  fixResults(data)
  {
    var data1 = data.data1;
    var data2 = data.data2;
    data2.labels.splice(0, 5);


    for (var k = 0; k < data1.datasets.length; k++)
    {
      var d1 = data1.datasets[k].data;
      data2.datasets[k].data = [];
      var d2 = data2.datasets[k].data;
    
      for (var i = 0; i < d1.length-7; i++) // not the 7 instead of 6 is because we don't trust the last day
      {
        var v = 0;
        for (var j = i+1; j < i+6; j++)
        {
          v += d1[j]-d1[j-1];
        }
        v = v/5;

        d2.push(v);
      }
    }
  }
*/
  componentDidMount()
  {

    helper.getFromServer("/user/graph_stocks?code="+this.code+"&date1="+this.date1)
    .then(res => {

      this.setState({data1: this.getDatasets(res.result), options: res.options});
      
    })

  }
render()
{

  if (!this.state.options)
  {
    return (<div><img src={loader} alt="loading..." /></div>)
  }
  return (
  <div className="container">
    <h4>Graph for {this.state.options.name}</h4> 
    <table className="graph-table">
      
      <tbody>
      <tr>
        <td>
          Price over time
        <Line data={this.state.data1} />
        </td>
        <td>
          7 day change in price over time
        <Line data={this.state.data2} />
        </td>
        </tr>
        </tbody>        
        </table>        
    </div>
);
}
}

export const Graph = Graph1;
