import React from "react";
import { DatePicker } from "../modules/omwui/index";
import loader from '../assets/loader.gif'

const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const TextPrompt = require("../comp_misc").textPrompt;
const TextAreaPrompt = require("../comp_misc").textAreaPrompt;
const misc = require("../comp_misc");


// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/user/comp_fin_strat";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    <td><a href={`stocks?pid=${props.item.id}&date1=${props.parent.state.date1}`}>{props.item.code}</a></td>
    <td><a href={`stocks?pid=${props.item.id}&date1=${props.parent.state.date1}&code=${props.item.code}`}>{props.item.strategy}</a></td>
    <td>{props.item.purchase}</td>
    <td>{props.item.current}</td>
    <td>{helper.formatMoney(props.item.nStart)}</td>
    <td>{helper.formatMoney(props.item.nCurrent)}</td>
    <td>{props.item.profit}</td>
<td><a href={`transactions?code=${props.item.code}&strat=${props.item.stratCode}&date1=${props.parent.state.date1}`}>Transactions</a></td>
<td><a href={`graph?code=${props.item.code}&strat=${props.item.stratCode}&date1=${props.parent.state.date1}`}>Graph</a></td>
<td><a href={`events?code=${props.item.code}&strat=${props.item.stratCode}`}>Events</a></td>

</tr>

}

class ModalList1 extends React.Component {
  
    q = helper.parseQuery();
    pid = this.q.pid;

    state = {
      rows: null,
      date1: this.q.date1 ? this.q.date1 : '2022-1-1',
      date2: this.q.date2,
      error:null,
      image: null,
    };


  filter(event)
  {
    const target = event.target;
//    const name = target.name;
    var value = target.value;

    this.fillList(value);

  }



fillList(filter)
{
  console.log("filling!");

  var rows1 = [];


  helper.getFromServer(g_link+`?pid=${this.pid}&date1=${this.state.date1}`)
  .then(res => {

    console.log(res.result)
    this.setState({showForm: false, rows: res.result, options: res.options});
  }).catch(err => {

    console.log(err)
    this.setState({showForm: false, rows: [], options: {}});

  })



}
 componentDidMount() {
  this.fillList()  
  }

  showModal(item)
  {
      
//    this.setState({showForm: true, image: "/api/media/logo"+item.id+".png", id: item.id, name: item.name, email: helper.checkNull(item.email), error: ""});
    this.setState({showForm: true, id: item.id, name: item.name,code: item.code, strategyId: item.strategyId, startDate: item.startDate, error: ""});
}

  hideModal()
  {
    this.setState({showForm: false});
  }

  saveItem()
  {
//    helper.postToServer(g_link, {dw: "update", id: this.state.id, email: this.state.email, name: this.state.name}, true)
    helper.postToServer(g_link, {dw: "update", id: this.state.id, name: this.state.name,code: this.state.code, strategyId: this.state.strategyId, 
      startDate: this.state.startDate}, true)
.then(res => {
      this.fillList();
    })
    .catch(err => {
      this.setState({error: err});
    })

  }
  addItem()
  {

    helper.postToServer(g_link, {dw: "add", parentId:this.state.parentId}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }

  deleteItem()
  {

    helper.postToServer(g_link, {dw: "del", id: this.state.id}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }

  
  hic(event) {

    const target = event.target;
    const name = target.name;

    let value;
//    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.type === 'checkbox')
    {
      value = this.state[name];
      if (!value)
        value = [];
        // assumes DOM won't feed us dupes
        var vint = parseInt(target.value);

      if (target.checked)
      {
        value.push(vint);
      }
      else
      {
        var index = value.indexOf(vint);
        if (index !== -1) value.splice(index, 1);
      }
    }
    else
    {
      value = target.value;
    }
    this.setState({
      [name]: value
    });
  
  }

  onFile() {

    var file = document.getElementById(undefined).files[0];
  
    var formData = new FormData();
    formData.append('file', file);
    formData.append('id', this.state.id)
    formData.append("dw", "saveFile")
    
    fetch(config.back+g_link+"_up", {
      method: 'POST', credentials: "same-origin",
      body: formData
    }).then(response => response.json())
    .then(res => {
      
      this.setState({image: "/api/media/!!upload_tag!!"+this.state.id+".png?"+new Date().getMilliseconds(),
    alert: "File was uploaded"});
    })
    .catch(error => { 
      alert(error);
    });
   //.then(response => console.log('Success:', response));
    
  }
    

  render() {
document.title = "Stocks";
let listItems;
//

if (this.state.rows)
{

  

  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

}
else
{
  return (<div><img src={loader} alt="loading..." /></div>)
}
return(

<div className="container">
  <br/>
Compare strategies<br/><br/>
<DatePicker parent={this} startDate={this.state.date1} endDate={this.state.date2} />  {this.state.date1}

<button onClick={() => {
  document.location = `/user/finance/compare?pid=${this.pid}&date1=${this.state.date1}&date2=${this.state.date2}`
}}>Refresh</button>

<br/><br/>

<table className="table">
<tbody>
  <tr>
    <th>Stock</th>
    <th>Strategy</th>
    <th>Purchase Price</th>
    <th>Current Value</th>
    <th># start</th>
    <th># end</th>
    <th>Profit</th>
    <th>Transactions</th>
    <th>Graph</th>
    <th>Events</th>
    </tr>
    
  {listItems}
  
	</tbody>
  </table>

  <Modal show={this.state.showForm} handleClose={this.hideModal.bind(this)}>
          <br/>
          <div><TextPrompt name="name" title="Name" onChange={this.hic.bind(this)} value={this.state.name}/></div>
        <button type="button" className="btn btn-primary" onClick={this.saveItem.bind(this)}>Save</button>&nbsp;
        <button type="button" className="btn btn-primary" onClick={this.deleteItem.bind(this)}>Delete</button>&nbsp;

</Modal>

</div>
)

}
}

export default ModalList1
